<app-external-header></app-external-header>
<!-- Main Page Start -->
<section class="banner">
  <div class="container">
    <!-- <owl-carousel-o [options]="customOptions" style="width: 100%;">
      <ng-template carouselSlide class="slide" style="width: 100%;">
        
      </ng-template>
    </owl-carousel-o> -->
    <div class="bannerContent">
      <div class=" row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="content">
            <span class="top-cta">Looking for a job</span>
            <h2 class="mainheading">Find Your perfect job match</h2>
            <p class="para2">Search the largest free veteran jobs board, jobs for veteran family members, build and
              post your resume to make the most out of your skills and experience in the civilian world.</p>
            <a class="borderBtn pd" href="javascript:void(0)" routerLink="/login">Post your resume</a>
            <a class="orangeBtn pd" href="javascript:void(0)" routerLink="/employer">Post a job</a>

          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ">
          <div class="img bk">
            <img src="assets/images/homepage/banner3.png" alt="banner">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- End -->
<!-- Job roles -->
<section class="pd-tb">
  <div class="container">
    <h3 class="subheading flexcss">Job roles <a href="javascript:void(0);" class="orange flexcss"
        routerLink="/login">See all <img src="assets/images/arrow.svg"></a></h3>
    <div class="mainJob">
      <div *ngFor="let jobs of userData?.jobRole; let i=index;" routerLink="/login" style="cursor: pointer;">
        <div class="greyBox" *ngIf="i<5">
          <h3>{{jobs?.name}}</h3>
        </div>
      </div>
      
      <div class="noDataFound" *ngIf="userData?.jobRole.length == 0">
        <h2>No Records Found</h2>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Latest Jobs -->
<section class="pd-tb padding_bottom" *ngIf="userData?.latestJobs != null">
  <div class="container">
    <h3 class="subheading flexcss">Latest jobs<a href="javascript:void(0);" class="orange flexcss"
        routerLink="/login">See all <img src="assets/images/arrow.svg"></a></h3>
    <!-- Row -->

    <div class="row">
      <!-- First Card -->
      <div *ngFor="let latest of userData?.latestJobs; let i=index" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
        <div class="card-grid-2 hover-up" *ngIf="i<4">
          <div class="card-grid-2-image-left">
            <div class="image-box"><img
                src="{{latest?.recruiter?.profileImage != null? recruiterImagePath : ''}}{{latest?.recruiter?.profileImage == null ? 'assets/images/profile-img.jpg' :latest?.recruiter?.profileImage}}"
                alt="jobBox"></div>
            <div class="right-info">
              <a class="name-job">{{latest?.recruiter?.companyName}}</a>
              <span class="location-small">{{latest?.recruiter?.location}}</span>
              <span *ngIf="latest?.isfeatured == 1" class="featured">Featured</span>
            </div>
          </div>


          <div class="card-block-info">
            <h6>{{latest?.jobTitle}}</h6>
            <p class="salary">{{jobSalary(latest)}}</p>
            <div class="mt-3">
              <span class="card-time">{{dateInMilisecond(latest?.createdAt) | dateAgoConvert}}</span> |
              <span class="card-applicants">{{latest?.applicantsCount}} Applicants</span> |
              <span class="card-briefcase">{{latest?.employement_type?.name || "-"}}</span>

            </div>

            <div class="job-description">
              <h6>Job description</h6>
              <p>{{latest?.description || "-"}}</p>
            </div>
            <div class="apply">
              <img src="assets/images/ic_unsave.svg" alt="unsave" routerLink="/login" title="Save">
              <a href="" class="borderBtn applybtn" routerLink="/login">Apply now</a>
            </div>
          </div>
        </div> <!--Main card div  -->
      </div> 
      <div class="noDataFound" *ngIf="userData?.latestJobs.length == 0">
        <h2>No Records Found</h2>
      </div>
    </div>
    <!-- End -->


  </div>
</section>
<!-- End -->
<!-- Employer Section -->
<section class="padding_bottom">

  <div class="container">
    <div class="employer">
      <div class="employerContent">
        <h5 class="orange">Employers</h5>
        <h3 class="second-heading">Find the right talent</h3>
        <p class="para2">We get it – hiring the right talent can be tough. Our Employer Solutions are here to help
          simplify the process, speed up time-to-hire, and make life a lot less stressful.</p>
        <a class="orangeBtn pd" routerLink="/employer">Discover the perfect candidate</a>
      </div>
    </div>

  </div>
</section>
<!-- End -->
<!-- Employers of choice-->
<section class=" choice padding_bottom" *ngIf="userData?.employersChoice != null">
  <div class="container">
    <h3 class="subheading flexcss">Employers of choice
      <a href="javascript:void(0);" class="orange flexcss" *ngIf="userData?.employersChoice.length>4"
        routerLink="/login">See all <img src="assets/images/arrow.svg"></a>
      </h3>
    <div class="mainJob noSpace">
      <div class="itemsBox" *ngFor="let employer of userData?.employersChoice; let i=index">
        <div class="greyBox pointer" *ngIf="i<5">
          <span class="featured">Featured</span>
          <img
            src="{{employer?.profileImage != null ?recruiterImagePath : ''}}{{employer?.profileImage == null ? 'assets/images/profile-img.jpg' : employer?.profileImage}}"
            alt="client">
        </div>
      </div>
    </div>
    <div class="noDataFound" *ngIf="userData?.employersChoice.length == 0">
      <h2>No Records Found</h2>
    </div>
  </div>
</section>
<!-- End -->

<!-- IT jobs-->
<section class="padding_bottom" *ngIf="userData?.ITJobs != null">
  <div class="container">
    <h3 class="subheading flexcss">IT jobs<a href="javascript:void(0);" class="orange flexcss" routerLink="/login">See
        all <img src="assets/images/arrow.svg"></a></h3>
    <!-- Row -->

    <div class="row">
      <!-- First Card -->
      <div *ngFor="let itJob of userData?.ITJobs; let i=index" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
        <div class="card-grid-2 hover-up" *ngIf="i<4">
          <div class="card-grid-2-image-left">
            <div class="image-box"><img
                src="{{itJob?.recruiter?.profileImage != null ? recruiterImagePath :''}}{{itJob?.recruiter?.profileImage == null ? 'assets/images/profile-img.jpg' : itJob?.recruiter?.profileImage}}"
                alt="jobBox"></div>
            <div class="right-info">
              <a class="name-job" href="/company-details">{{itJob?.recruiter?.companyName}}</a>
              <span class="location-small">{{itJob?.recruiter?.location}}</span>
              <span *ngIf="itJob?.isfeatured == 1" class="featured">Featured</span>
            </div>
          </div>


          <div class="card-block-info">
            <h6>{{itJob?.jobTitle}}</h6>
            <p class="salary">{{jobSalary(itJob)}}</p>
            <div class="mt-3">
              <span class="card-time">{{dateInMilisecond(itJob?.createdAt) | dateAgoConvert}}</span> |
              <span class="card-applicants">{{itJob?.applicantsCount}} Applicants</span> |
              <span class="card-briefcase">{{itJob?.employement_type?.name || "-"}}</span>

            </div>

            <div class="job-description">
              <h6>Job description</h6>
              <p>{{itJob?.description || "-"}}</p>
            </div>
            <div class="apply">
              <img src="assets/images/ic_unsave.svg" alt="unsave" routerLink="/login" title="Save">
              <a href="" class="borderBtn applybtn" routerLink="/login">Apply now</a>
            </div>
          </div>
        </div> <!--Main card div  -->
      </div>
      <div class="noDataFound" *ngIf="userData?.ITJobs.length == 0">
        <h2>No Records Found</h2>
      </div>
    </div>
    <!-- End -->
  </div>
</section>
<!-- End -->
<section class="padding_bottom">
  <div class="container">
    <div class=" employer hire ">
      <div class="hire-content">
        <h3 class="third-heading white">Let us help you hire the <span class="orange">best talent. </span> </h3>
        <p class="para-3">We get it – hiring the right talent can be tough. Our Employer Solutions are here to help
          simplify the process, speed up time-to-hire, and make life a lot less stressful.</p>
        <a href="" class="orangeBtn pd" routerLink="/employer">Post a Job</a>
      </div>
    </div>
  </div>
</section>
<!-- Remote jobs-->
<section class="padding_bottom" *ngIf="userData?.remoteJobs != null">
  <div class="container">
    <h3 class="subheading flexcss">Remote jobs<a href="javascript:void(0);" class="orange flexcss"
        routerLink="/login">See all <img src="assets/images/arrow.svg"></a></h3>
    <!-- Row -->

    <div class="row">
      <!-- First Card -->
      <div *ngFor="let remote of userData?.remoteJobs; let i=index" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
        <div class="card-grid-2 hover-up" *ngIf="i<4">
          <div class="card-grid-2-image-left">
            <div class="image-box"><img
                src="{{remote?.recruiter?.profileImage != null ? recruiterImagePath : ''}}{{remote?.recruiter?.profileImage == null ? 'assets/images/profile-img.jpg' : remote?.recruiter?.profileImage}}"
                alt="jobBox"></div>
            <div class="right-info">
              <a class="name-job">{{remote?.recruiter?.companyName?.length > 30 ? remote?.recruiter?.companyName.substring(0,30)+"..." : remote?.recruiter?.companyName}}</a>
              <span class="location-small">{{remote?.recruiter?.location}}</span>
              <span *ngIf="remote?.isfeatured == 1" class="featured">Featured</span>
            </div>
          </div>


          <div class="card-block-info">
            <h6>{{remote?.jobTitle}}</h6>
            <p class="salary">{{jobSalary(remote)}}</p>
            <div class="mt-3">
              <span class="card-time">{{dateInMilisecond(remote?.createdAt) | dateAgoConvert}}</span> |
              <span class="card-applicants">{{remote?.applicantsCount}} Applicants</span> |
              <span class="card-briefcase">{{remote?.employement_type?.name || "-"}}</span>

            </div>

            <div class="job-description">
              <h6>Job description</h6>
              <p>{{remote?.description || "-"}}</p>
            </div>
            <div class="apply">
              <img src="assets/images/ic_unsave.svg" alt="unsave" routerLink="/login" title="Save">
              <a href="" class="borderBtn applybtn" routerLink="/login">Apply now</a>
            </div>
          </div>
        </div> <!--Main card div  -->
      </div> <!--End First card div  -->
      
      <div class="noDataFound" *ngIf="userData?.remoteJobs.length == 0">
        <h2>No Records Found</h2>
      </div>
    </div>
    <!-- End -->
  </div>
</section>


<!-- End -->
<app-footer></app-footer>