<app-external-header></app-external-header>
<!-- progress -->
<div class="progress">
  <div class="progress-bar" role="progressbar" aria-label="Basic example" style="width:55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
</div>


<!-- End -->

<!-- Main Page Start -->
<section class="loginPage margin-top-2">
  <div class="container">
      <div class="verificationWrap setPassword">
          <div class=" subHeader">
              <h2 class="mainTitle"> Set Password </h2>
              <p> Create your own strong password. </p>
          </div>
          <form [formGroup]="form" (ngSubmit)="setPassword()">
               <div class="form-group" style="margin-bottom: 12px;">
                  <label> Password </label>
                  <input  type="{{showPwd?'text':'password'}}" class="form-control" name="" placeholder="Enter password"  id="pwd" formControlName="password"  (keyup)="keyup($event)"/>
                  <a href="javascript:void(0);" [class]="showPwd? 'hide':'show'" (click)="showPwd= !showPwd"> 
                    <img class="hide" [src]="showPwd? '../../../assets/images/password.svg':'../../../assets/images/ic_eye_open.svg'" alt="password"> 
                    <i ></i>
                  </a>
              </div>

              <ul class="selectpasswordType">
                  <li [ngClass]="form.value.password.length == 0 ? '': (form.value.password.length > 7 ? 'active':'text-danger')"> Minimum 8 characters </li>
                  <li [ngClass]="form.value.password.length == 0 ? '' : (upper > 0 ? 'active' : 'text-danger')"> Minimum 1 uppercase character.</li>
                  <li [ngClass]="form.value.password.length == 0 ? '' : (number > 0 ? 'active' : 'text-danger')"> Minimum 1 number  </li>
                  <li [ngClass]="form.value.password.length == 0 ? '' : (special > 0 ? 'active' : 'text-danger')"> At least 1 special character </li>
              </ul>
             <div class="text-danger" *ngIf="form.controls['password'].hasError('required') && showError">
                *Please enter password.
            </div>
             <div class="text-danger" *ngIf="form.value.password.includes(' ') && showError">
                *Space not allowed in password.
            </div>
            <!-- <div class="text-danger" *ngIf="(!form.controls['password'].hasError('minlength') && !form.controls['password'].hasError('required') && !SignUpPwdValid && showError) || (!SignUpPwdValid && form.value.password.length>2)">
                *Password must be follow Above  notes.
            </div> -->
              <div class="form-group">
                <input type="submit" value="Continue" class="commonBtn mt-5"/>
                  <!-- <a href="javascript:void(0)" routerLink="/upload-document" > Continue </a> -->
              </div>

          </form>
      </div>
  </div>
</section>
