import { Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild, HostListener } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { DataSharingService } from "../../services/data.share.service";
import swal from "sweetalert2";
import { HttpParams } from "@angular/common/http";
import { ImageUrls } from "../../services/s3buket";
import { HeaderJobseekerService } from "./header-jobseeker.service"
import { MessagingService } from "../../services/messaging.service";
import { UserDetailsService } from "../../services/user/user-details.service";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-header-jobseeker",
  templateUrl: "./header-jobseeker.component.html",
  styleUrls: ["./header-jobseeker.component.css"]
})
export class HeaderJobseekerComponent implements OnInit {
  toggleSidebar = false;
  userData: any;
  userDatas: any;
  globalUrl = ImageUrls.BaseUrl + ImageUrls.directories.users
  showHead: boolean = false;
  showLogout: boolean = false;
  noData: boolean = false;
  profileImage;
  deviceToken: any;
  message;
  limit = 4;
  skip = 1;
  allData: any = [];
  unreadCount: any;
  closeSearch = false;
  allJobs: any = [];
  search: any;
  searchObserver = new Subject<string>();

  movedByEnterPress = false;

  show = true;
  isDropdownOpen = false;
  constructor(private el: ElementRef, private renderer: Renderer2, private router: Router, public route: ActivatedRoute, private api: AdminService, private dataSharingService: DataSharingService, private headerService: HeaderJobseekerService, private fcm: MessagingService, private userDetailsService: UserDetailsService, private admin: AdminService) {
    this.userDetailsService.getUserData();
    this.userDetailsService.userDetailsObserver.subscribe(userData => {
      this.userDatas = userData;
    });
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.urlAfterRedirects.endsWith("/home")) {
          this.showHead = true;
          this.showLogout = false;
        } else if (this.router.url.endsWith("/upload-document") || this.router.url.endsWith("/complete-profile")) {
          this.showLogout = true;
        }
        else {
          this.showLogout = false;
          this.showHead = false;
        }
      }
    })
    this.api.getSearch().subscribe(search => {
      this.search = search
      this.closeSearch = false;
    });
    this.getNotification();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      // this.movedByEnterPress = true;
    }
  }
  ngOnInit(): void {
    this.deviceToken = localStorage.getItem("deviceToken")
    this.fcm.requestPermission()
    // this.fcm.receiveMessage()
    this.fcm.currentMessage.subscribe((msg) => {
      this.message = msg
      this.getNotification()
    })

    this.searchObserver.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe(value => {
        if (value == "") {
          this.allJobs = [];
        } else {
          this.getJobs();
        }
      });

  }
  onClickBell() {
    this.message = null;
    this.getNotification()
  }
  getNotification() {
    this.api.stopLoader();
    let params = new HttpParams();
    params = params.append("skip", this.skip.toString());
    params = params.append("limit", this.limit.toString());
    this.api.getData(apiUrl._notification, params).subscribe(async (res) => {
      this.allData = res.data.data;
      this.unreadCount = res.data.unreadCount;
    },
      err => {
        console.log(err.error.message, "errr")
      })
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("deviceToken");
    if ((deviceToken) && (deviceToken != "")) {
      return deviceToken;
    }
    else {
      this.fcm.requestPermission();
      deviceToken = localStorage.getItem("fireBaseToken")
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }

  toggleNavbar(event: any) {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("sidebar-mini");
    if ((this.toggleSidebar = !this.toggleSidebar)) {
    } else {
      body.classList.remove("sidebar-mini");
    }
  }
  redirectTo(data) {
    const updateStatus = {
      id: data.id,
      isRead: 1
    }
    this.api.putData(apiUrl._notification, updateStatus)
      .subscribe(async (res) => {
        if (data.notificationType == "1") {
          this.router.navigate(["/notification"]);
        } else if (data.notificationType == "11") {
          this.router.navigate(["/applied-jobs"]);
        } else {
          this.router.navigate(["/profile"]);
        }
      })
  }
  logout() {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      swal.fire({
        html: '<p class="gilroy-bold">Are you sure you want to Logout?</p>',
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        customClass:  {
          popup:"post-popup logout-pop"},
        confirmButtonAriaLabel: "Yes",
        cancelButtonAriaLabel: "Cancel",
        confirmButtonColor: "#FE831D",
        cancelButtonColor: "#5A50CC",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: true,
        width: 500,

      }).then((result) => {
        if (result.value) {
          const data = {
            "deviceToken": this.deviceToken
          }
          this.api.putData(apiUrl._logout, data).subscribe(
            res => {
              this.clearLocalStorage();
            });
        }
        else if (result.isDismissed == true) {
        }
      })
    } else {
      this.clearLocalStorage();
    }

  }
  clearLocalStorage() {
    window.localStorage.clear();
    this.fcm.currentMessage.unsubscribe()
    localStorage.clear()
    localStorage.removeItem("accessToken");
    localStorage.removeItem("deviceToken");
    localStorage.removeItem("isPasswordSet");
    localStorage.removeItem("login_type");
    localStorage.removeItem("userType");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("s3Folders");
    this.router.navigate(["/home"]);
  }
  searchFun() {
    if (this.search != '') {
      // this.closeSearch = true;
      this.api.setSearch(this.search);
      this.getJobs();
      this.isDropdownOpen = false;
      this.movedByEnterPress = true;
      this.router.navigate(['/search/' + this.search])
    } else {
      this.closeSearch = false;
    }
  }
  onTextChange(value: any) {
    this.movedByEnterPress = false;
    this.search = value
    if (this.search != '' && this.search.length > 0) {
      this.api.setSearch(this.search);
      this.closeSearch = true;
      //this.getJobs();
      this.searchObserver.next(this.search)
    } else {
      this.closeSearch = false;
      this.search = '';
      this.allJobs = [];
    }
  }
  refreshPage() {
    this.closeSearch = false;
    this.search = '';
    this.allJobs = [];
    this.noData = false;
    this.api.setSearch(this.search);
  }

  getJobs() {
    this.admin.showLoader = false;
    let params = new HttpParams();
    params = params.append("skip", 1);
    params = params.append("limit", 10);
    if (this.search != null && this.search != "") {
      params = params.append("search", this.search);
    }
    this.api.postData(apiUrl._searchAutocomplete, params)
      .subscribe(async (res) => {
        if (!this.movedByEnterPress) {
          this.isDropdownOpen = true;
          this.allJobs = res.data.hits.hits;
          if (res.data.hits.hits == null || res.data.hits.hits == "") {
            this.noData = true;
            setTimeout(() => {
              this.noData = false;
            }, 1500);

          }
        }
      })
  }

  openSearchPage(searchContent) {
    this.allJobs = [];
    this.search = searchContent["name"] ? searchContent?.name : searchContent?.searchKey
    let params = new HttpParams();
    params = params.append("searchKey", this.search);
    this.api.postData(apiUrl._saveRecentSearch, params)
      .subscribe(async (res) => {
        this.router.navigate(['/search/' + this.search])
      })
  }
  recentSearchList(search) {
    this.allJobs = [];
    this.search = search;
    this.recentSearch()
  }

  recentSearch() {
    this.admin.showLoader = false;
    let params = new HttpParams();
    this.api.postData(apiUrl._recentSearch, params)
      .subscribe(async (res) => {

        this.isDropdownOpen = true;
        let result = res.data.hits.hits;

        let uniqueNames: String[] = []

        result.map((item) => {
          if (!uniqueNames.includes(item["_source"]["searchKey"])) {
            this.allJobs.push(item);
            uniqueNames.push(item["_source"]["searchKey"]);
          }
        })

      })
  }
  removeDuplicates(_source: any, arg1: string): any {
    throw new Error("Method not implemented.");
  }

  menu_icon_variable: boolean = false;
  menuVariable: boolean = false;

  openMenu() {
    this.menuVariable = !this.menuVariable;
    this.menu_icon_variable = !this.menu_icon_variable;
  }

  drop() {
    this.show = !this.show;
    this.noData = false;
  }
  autoCloseForDropdownCars(event) {
    var target = event.target;
    if (!target.closest(".show")) {
      // this.show = !this.show;
    }
  }
}
