<app-external-header></app-external-header>
<!-- progress -->
<div class="progress">
  <div class="progress-bar" role="progressbar" aria-label="Basic example" style="width:10%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
</div>

<!-- End -->

<!-- Main Page Start -->
<section class="loginPage">
  <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4">
            <div class="choose-yourself">
              <h2 class="mainTitle"> How are you associated with veteran? </h2> 
            </div>
            <form [formGroup]="registerForm">
            <ul class="selectAvtara">
                <li>
                    <input type="radio" name="veteranRelationName" id="radio1" class="selectAvtara_radio" formControlName="veteranRelationName" value="1"/>
                    <label for="radio1" class="selectAvtara_label"><span class="avtara"><img src="assets/images/ic_spouse_active.svg" /></span> <span class="avtaraName"> Spouse </span> </label>
                </li>

                <li>
                    <input type="radio" name="veteranRelationName" id="radio2" class="selectAvtara_radio" formControlName="veteranRelationName" value="2"/>
                    <label for="radio2" class="selectAvtara_label"><span class="avtara"><img src="assets/images/ic_chlid.svg" /></span> <span class="avtaraName"> Child </span> </label>
                </li>
                <li>
                    <input type="radio" name="veteranRelationName" id="radio3" class="selectAvtara_radio" formControlName="veteranRelationName" value="3"/>
                    <label for="radio3" class="selectAvtara_label"><span class="avtara"><img src="assets/images/ic_parents.svg" /></span> <span class="avtaraName">Parents </span> </label>
                </li>
            </ul> 
            <div class="text-danger" *ngIf="registerForm.controls['veteranRelationName'].hasError('required') && showError">
              *Please select on option.
            </div>
            <input type="submit" class="commonBtn" value="Continue" name="" (click)="onSubmit()">
          </form>
        </div>
        <div class="col-lg-8 col-md-8">
            <div class="loginImg"></div>
        </div>
      </div>
  </div>
</section>

<!-- End -->