import { Component, NgZone, OnInit, TemplateRef } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ImageUrls } from "src/app/services/s3buket";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormGroup, FormBuilder, Validators } from "@angular/forms"; 
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "../shared.service";
import { MessagingService } from "../../services/messaging.service"
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AuthApiService } from "src/app/services/social-account.service";
import { UserDetailsService } from "src/app/services/user/user-details.service";
@Component({
  selector: "app-job-seekers",
  templateUrl: "./job-seekers.component.html",
  styleUrls: ["./job-seekers.component.css"]
})
export class JobSeekersComponent implements OnInit {
  accessToken;
  allData:any=[];
  showError = false;
  public registerForm: FormGroup;
  globalUrl = ImageUrls.BaseUrl + ImageUrls.directories.admin;
  customOptions: OwlOptions = {
      loop: false,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      dots: true,
      navSpeed: 400,
      nav: true,
      navText: ["prev", "next"],
      center: false,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    }; 
    modalRef?: BsModalRef;
    constructor(private modalService: BsModalService, private authAPI: AuthApiService,
      private formBuilder: FormBuilder, public route: ActivatedRoute, private router: Router,
      private api: AdminService, private toastrService: ToastrService, public zone: NgZone,
      private sharedService: SharedService, private angularFireMessaging: AngularFireMessaging,
      private fcm: MessagingService, private afAuth: AngularFireAuth, private userDetailsService: UserDetailsService) {
   
      this.registerForm = this.formBuilder.group({
        userType: ["", Validators.compose([Validators.required])],
      });
    }
  ngOnInit(): void {
    this.accessToken = localStorage.getItem("accessToken");
    this.getEmployer();
  }
  // get state
  getEmployer(){
    this.api.getDatas(apiUrl._employerHome)
    .subscribe(async (res) => {
      this.allData = res.data.data;
    })
  }
  
  open(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray registerModal" })
    );
  }
  // for register screen type
  signUp() {
    if (this.registerForm.valid) {
      const userType = this.registerForm.get("userType")?.value;
      this.sharedService.userType = userType;
      localStorage.setItem("userType", userType)
      if (userType == 0) {
        this.router.navigate(["register"]);
      } else {
        this.router.navigate(["choose-yourself"]);
      }
      this.modalRef?.hide();
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 1000);
    }
  }
}
