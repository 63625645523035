import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { DataSharingService } from "../../services/data.share.service";
import swal from "sweetalert2";
import { HttpParams } from "@angular/common/http";
import { ImageUrls } from "src/app/services/s3buket";
import { HeaderEmpService } from "./header-emp.service"
import { MessagingService } from "src/app/services/messaging.service";
import { UserDetailsService } from "src/app/services/user/user-details.service";

@Component({
  selector: 'app-header-emp',
  templateUrl: './header-emp.component.html',
  styleUrls: ['./header-emp.component.css']
})
export class HeaderEmpComponent implements OnInit {
  toggleSidebar = false;
  userData: any;
  globalUrl = ImageUrls.BaseUrl + ImageUrls.directories.recruiter
  showHead: boolean = false;
  showLogout: boolean = false;
  profileImage;
  deviceToken: any;
  userId;
  userDatas;
  constructor(private router: Router, public route: ActivatedRoute, private api: AdminService, private dataSharingService: DataSharingService, private headerService: HeaderEmpService, private fcm: MessagingService, private userDetailsService: UserDetailsService) {
    this.userDetailsService.getRecruiterUserData();
    this.userDetailsService.userDetailsObserver.subscribe(userData => {
      this.userDatas = userData;
    });
    // this.dataSharingService.isProfileChanged.subscribe(value => { 
    //   this.userDatas = localStorage.getItem('userData')  
    //   if (!(localStorage.getItem('userData') == 'null' || localStorage.getItem('userData') == null)) {
    //     this.userDatas = JSON.parse(localStorage.getItem("userData")!!.toString());
    //   }
    //   this.userId =this.userDatas?.id

    // });
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // if (val.url.endsWith("/home")){
        if (this.router.url.endsWith("/edit-profile") || this.router.url.endsWith("/company-profile") || this.router.url.endsWith("/subscription") || this.router.url.endsWith("/inventory") || this.router.url.endsWith("/checkout")) {
          this.showHead = false;
        } else {
          this.showHead = true;
        }
      }
    })
  }

  ngOnInit(): void {
    this.userData = localStorage.getItem("userDetail")
    this.userDatas = localStorage.getItem("userData")
    this.deviceToken = localStorage.getItem("deviceToken")

  }

  getDeviceToken() {
    let deviceToken = localStorage.getItem("deviceToken");
    if ((deviceToken) && (deviceToken != "")) {
      return deviceToken;
    }
    else {
      this.fcm.requestPermission();
      deviceToken = localStorage.getItem("fireBaseToken")
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }

  toggleNavbar(event: any) {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("sidebar-mini");
    if ((this.toggleSidebar = !this.toggleSidebar)) {
    } else {
      body.classList.remove("sidebar-mini");
    }
  }
  getUserDetail() {
    this.api.getDatas(apiUrl._getRecuriterDetail + "/" + this.userId)
      .subscribe((res) => {
        this.userDatas = res.data.Recruiter;
        this.profileImage = this.globalUrl + this.userDatas?.profileImage
      })
  }

  logout() {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      swal.fire({
        html: '<p class="gilroy-bold">Are you sure you want to Logout?</p>',
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        customClass:  {
          popup:"post-popup logout-pop"},
        confirmButtonAriaLabel: "Yes",
        cancelButtonAriaLabel: "Cancel",
        confirmButtonColor: "#FE831D",
        cancelButtonColor: "#5A50CC",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: true,
        width: 500,

      }).then((result) => {
        if (result.value) {
          const data = {
            "deviceToken": this.deviceToken
          }
          this.api.putData(apiUrl._LogoutEmp, data).subscribe(
            res => {
              this.clearLocalStorage()

            });
        }
        else if (result.isDismissed == true) {
        }
      })
    } else {
      this.clearLocalStorage()
    }

  }
  clearLocalStorage() {
    localStorage.clear();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("deviceToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("isPasswordSet");
    localStorage.removeItem("login_type");
    localStorage.removeItem("userType");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("userData");
    localStorage.removeItem("s3Folders");
    localStorage.removeItem("isNotificationSetting");
    localStorage.removeItem("isNotificationSMSSetting");
    this.router.navigate(["/home"]);

  }
}
