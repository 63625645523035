<app-external-header></app-external-header>
<!-- progress -->
<div class="progress">
  <div class="progress-bar" role="progressbar" aria-label="Basic example" style="width:30%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
</div>


<!-- End -->

<!-- Main Page Start -->
<section class="loginPage margin-top-2">
  <div class="container">
      <div class="verificationWrap">
          <h2 class="mainTitle"> Verification Code </h2>
          <p> To verify your identity, please enter the OTP code we have sent to your email <strong> {{userData.email}}</strong> <a href="javascript:void(0);" (click)="back()"><img src="assets/images/ic_edit.svg" alt="edit"  style="margin-top:5px; height:15px;" ></a> </p>
          <form [formGroup]="form"> 
              <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent" [config]="config"  (keypress)="preventNumbers($event)"></ng-otp-input>
              <div class="text-danger" *ngIf="showError">
                *Please enter otp.
            </div>
              <p *ngIf="!resendOtp"> Sending OTP in <span class="orangeText" id="timer"> {{display}} </span></p>
              <p *ngIf="resendOtp"> Didn’t receive the code? <a  href="javascript:void(0);" class="orangeText" (click)="resendOTP()"> Resend </a> </p>

              
              <div class="form-group">
                <input type="submit" value="Verify" (click)="verify()" class="commonBtn mt-5"> 
              </div>

          </form>

      </div>
  </div>
</section>

<!-- End -->