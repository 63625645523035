export const apiUrl = {
	// common upload
	_upload: "api/v1/common/getSignedURL",
	_commonServices: "api/v1/common/services",

	// term & privacy
	_privacy: "api/v1/user/privacy-policy",
	_terms: "api/v1/user/terms",
	_about: "api/v1/user/about",
	// login
	_userLogin: "api/v1/user/login",
	_socialLogin: "api/v1/user/social/login",
	_logout: "api/v1/user/logout",
	_forgotPassword: "api/v1/user/forgotPassword",
	_changePassword: "api/v1/user/changePassword",
	// register
	_userRegister: "api/v1/user/register",
	_otpVerify: "api/v1/user/verify-otp",
	_otpResend: "api/v1/user/resendSignUpOtp",
	_setPassword: "api/v1/user/setUserPassword",
	// get user profile satatus
	_getResume: "api/v1/buildResume/getResume",
	_getUserResumeStatus: "api/v1/user/getUserResumeStatus",

	_updateAuthDocument: "api/v1/user/uploadAuthenticityDocuments",
	_updateAuthDocumentV1: "api/v1/user/uploadAuthenticityDocumentsV1",
	_updateProfile: "api/v1/user/updateProfile",
	_uploadDocument: "api/v1/user/uploadDocuments",
	_updateUploadDocument: "api/v1/user/updateUploadDocuments",
	_getDocument: "api/v1/user/getAllUploadDocuments",
	_delDocument: "api/v1/user/deleteUploadDocuments",
	_documentsParsing: "api/v1/user/documentsParsing",
	_getUserInfo: "api/v1/user/getUserDetails",
	_getMosListing: "api/v1/user/mosListing",

	// user build resume
	_getSkill: "api/v1/buildResume/getSkills",
	_searchSkills: "api/v1/common/searchSkills",
	_updateSkill: "api/v1/buildResume/addUpdateSkills",
	_updateSkillV1: "api/v1/buildResume/addUpdateSkillsV1",
	// education
	_allUniSpec: "api/v1/common/allUniversitiesAndSpecializations",
	_getEdu: "api/v1/buildResume/getEducation",
	_addEdu: "api/v1/buildResume/addEducation",
	_addEduV1: "api/v1/buildResume/addEducationV1",
	_updateEdu: "api/v1/buildResume/updateEducation",
	_updateEduV1: "api/v1/buildResume/updateEducationV1",
	_delEdu: "api/v1/buildResume/deleteEducation",
	// work
	_allEmpType: "api/v1/common/employmentTypes",
	_allJobCom: "api/v1/common/allIndustriesAndJobTitle",
	_getWorkExp: "api/v1/buildResume/getWorkExperience",
	_addWorkExp: "api/v1/buildResume/addWorkExperience",
	_updateWorkExp: "api/v1/buildResume/updateWorkExperience",
	_delWorkExp: "api/v1/buildResume/deleteWorkExperience",
	// volunteering
	_getVolExp: "api/v1/buildResume/getVolunteerExperience",
	_addVolExp: "api/v1/buildResume/addVolunteerExperience",
	_updateVolExp: "api/v1/buildResume/updateVolunteerExperience",
	_delVolExp: "api/v1/buildResume/deleteVolunteerExperience",
	// projects
	_getProjects: "api/v1/buildResume/getProjectTaken",
	_addProjects: "api/v1/buildResume/addProjectTaken",
	_updateProjects: "api/v1/buildResume/updateProjectTaken",
	_delProjects: "api/v1/buildResume/deleteProjectTaken",
	//awards
	_getAwards: "api/v1/buildResume/getAwardsAndHonors",
	_addAwards: "api/v1/buildResume/addAwardsAndHonors",
	_updateAwards: "api/v1/buildResume/updateAwardsAndHonors",
	_delAwards: "api/v1/buildResume/deleteAwardsAndHonors",
	// linence
	_getLiences: "api/v1/buildResume/getLicenseAndCertification",
	_addLiences: "api/v1/buildResume/addLicenseAndCertification",
	_updateLiences: "api/v1/buildResume/updateLicenseAndCertification",
	_delLiences: "api/v1/buildResume/deleteLicenseAndCertification",
	// language
	_getLanguage: "api/v1/buildResume/getLanguage",
	_addLanguage: "api/v1/buildResume/addLanguage",
	_addLanguageMultiple: "api/v1/buildResume/addLanguageMultiple",
	_updateLanguage: "api/v1/buildResume/updateLanguage",
	_delLanguage: "api/v1/buildResume/deleteLanguage",
	// job
	_getAllIndutry: "api/v1/common/allIndustriesAndJobTitle",
	_getAllSecurityTravelRequirements: "api/v1/common/getAllsecurityClearanceAndtravelRequirements",
	_getJobPreference: "api/v1/buildResume/getJobPreference",
	_addJobPreference: "api/v1/buildResume/addJobPreference",
	_addJobPreferenceV1: "api/v1/buildResume/addJobPreferenceV1",
	_updateJobPreference: "api/v1/buildResume/updateJobPreference",
	_deleteJobPreference: "api/v1/buildResume/deleteJobPreference",
	_userMobileVerify: "api/v1/user/userMobileVerify",
	_userMobileVerifyOtp: "api/v1/user/userMobileVerifyOtp", 

	// recuriter api
	_empUpload: "recruiter/v1/common/getSignedURL",
	_employerHome: "recruiter/v1/common/landingPage",
	_stateApi: "recruiter/v1/recruiter/state",
	_LoginEmp: "recruiter/v1/recruiter/login",
	_LogoutEmp: "recruiter/v1/recruiter/logout",
	_forgotEmp: "recruiter/v1/recruiter/forgot-password",
	_registerEmp: "recruiter/v1/recruiter/register",
	_otp: "recruiter/v1/recruiter/verify-otp",
	_resendSignUpOtp: "recruiter/v1/recruiter/resendSignUpOtp",
	_setUserPassword: "recruiter/v1/recruiter/setUserPassword",
	_recUpdateProfile: "recruiter/v1/recruiter/updateProfile",
	_getRecuriterList: "recruiter/v1/recruiter/list",
	_getRecuriterDetail: "recruiter/v1/recruiter/recruiter",
	_paymentPlan: "recruiter/v1/recruiter/paymentPlan",
	_paymentPlanDetail: "recruiter/v1/recruiter/paymentPlan/detail",
	_planFree: "recruiter/v1/candidate/activateFreePlan",

	_addCard: "recruiter/v1/candidate/addCard",
	_getAllCards: "recruiter/v1/candidate/getAllCardlist",
	_addSubcriptionPlan: "recruiter/v1/candidate/recruiterSubcriptionPlan",
	_addSubcriptionPlanV1: "recruiter/v1/candidate/recruiterSubcriptionPlanV1",
	_addPurchasePlan: "recruiter/v1/candidate/recruiterPurchaseInventroyPlan",
	_addPurchasePlanV1: "recruiter/v1/candidate/recruiterPurchaseInventroyPlanV1",
	_setUpPaymentsIntent: "recruiter/v1/candidate/setUpPaymentsIntent",
	_chatHistory:"api/v1/chat/chatHistory",

	_recruiterChats:"api/v1/recruiterChats/chatHistory",

	// jobs
	_getJobsListing: "recruiter/v1/recruiter/jobpost/getList/web",
	_getJobsListingBySubRecruiter: "recruiter/v1/recruiter/jobpost/getList/webByRecruiter",
	_getJobs: "recruiter/v1/recruiter/jobpost",
	_getJobs1: "recruiter/v1/recruiter/jobpost/v1",
	_getActiveJobs: "recruiter/v1/recruiter/jobpost/activeJobsLists",
	_saveJobs: "recruiter/v1/recruiter/jobpost/saveJob",
	_deleteJobs: "recruiter/v1/recruiter/jobpost",
	_addJobs: "recruiter/v1/recruiter/jobpost/add",
	_getCategories: "recruiter/v1/common/categories",
	_getIndus: "recruiter/v1/common/industry",
	_getSkills: "recruiter/v1/common/skills",
	_getEmploymentTypes: "recruiter/v1/common/employmentTypes",
	_getSchedules: "recruiter/v1/common/schedules",
	_getBenefits: "recruiter/v1/common/benefits",
	_getSupplements: "recruiter/v1/common/supplements",
	_getQuestions: "recruiter/v1/common/screening-question",
	_detailJobs: "recruiter/v1/recruiter/jobpost/v1",
	_getJobsApplicant: "recruiter/v1/recruiter/jobpost/applicant/detail",
	_getJobsUser: "recruiter/v1/recruiter/jobpost/getUser",
	_statusUpdate: "recruiter/v1/recruiter/jobpost/applicant/statusUpdate",
	_statusReason: "recruiter/v1/recruiter/jobpost/jobClose/reason",
	_getSuggested: "recruiter/v1/recruiter/jobpost/suggested",
	_getSortListed: "recruiter/v1/recruiter/jobpost/suggested/sortListed",
	_getHide: "recruiter/v1/recruiter/jobpost/suggested/hide",
	_getUserAllNotes: "recruiter/v1/recruiter/jobpost/getUserAllNotes",
	_updateNotes: "recruiter/v1/recruiter/jobpost/userNotes/update",
	_deleteNotes: "recruiter/v1/recruiter/jobpost/userNotes/delete",
	_getActivityDetail: "recruiter/v1/recruiter/jobpost/activity/detail",
	_searchJobTitle: "recruiter/v1/common/searchJobTitle",
	_addFavourite: "recruiter/v1/candidate/favourite",

	// Users
	_users: "recruiter/v1/users",
	_usersDetail: "recruiter/v1/users/detail",

	// Group Permission
	_groupPermission: "recruiter/v1/group-permission",
	_groupDetail: "recruiter/v1/group-permission/detail",
	_manageUsers: "recruiter/v1/group-permission/manage",
	_manageUsersDetail: "recruiter/v1/group-permission/manage/detail",

	// common api
	_getEligibles: "recruiter/v1/common/eligibles",
	_getPersonalities: "recruiter/v1/common/personalities",
	_getTravelRequirements: "recruiter/v1/common/travelRequirements",
	_getHire: "recruiter/v1/common/hires",

	// job seeker home api
	_dashboardForActiveUser: 'api/v1/home',
	_dashboardForGuestUser: 'api/v1/home/guestUser', 

	// save unsave job
	_saveUnsaveJob: "api/v1/jobs/save",
	// applied jobs
	_getAppliedJobs: "api/v1/jobs/apply",
	_getJobDetail: "api/v1/jobs/JobsList",

	// Saved jobs
	_getSavedJobs: "api/v1/jobs/save", 

	//search/filter jobs
	_searchFilterJobs: "api/v1/home/jobpost",
	_searchJobs: "api/v1/home/searchJobs",
	_searchAutocomplete: "api/v1/home/autocomplete",
	_saveRecentSearch: "api/v1/home/saveRecentSearch",
	_recentSearch: "api/v1/home/recentSearch",

	//get job details
	_jobDetails: "api/v1/jobs/JobsList",

	//apply job
	_applyJob: "api/v1/jobs/apply",

	//recommended jobs
	_recommendedJobs: "api/v1/home/recommendedJob",

	// Industry jobs
	_industryJobs: "api/v1/home/jobpost",

	// jobseeker
	_deleteAccount: "api/v1/user/deleteUserAccount",
	_downloadResume: "api/v1/user/downloadResume",
	_notification: "api/v1/notification",

	//employer module
	_employersList: "api/v1/user/recruiterlist",
	_employerDetailsById: "api/v1/user/recruiterDetails",
	_employerPostedJobs: "api/v1/jobs/companyPostJob",
	_downloadResumeSample: "api/v1/common/downloadResumeSample",

	_getLastStepper: "api/v1/user/getUserResumeStatus",
	_getFilterOptions: "api/v1/common/getCommanAll", 

	_jobRolesList: "api/v1/common/getCategories",
	_jobcategories: "api/v1/common/categories",
	_jobgetSubCategories: "api/v1/common/getSubCategories",
	_recommendedJob: "api/v1/home/recommendedJob",
	latestJob: "api/v1/home/latestJob",

	_resumeUploadForParsing: "api/v1/user/documentsParsing", // post

	// candidated
	_getFavCandidates:"recruiter/v1/candidate/favourite",
	_getS3SignedURL: "api/v1/common/getSignedURL",
	_getSkippedCandidates:"recruiter/v1/candidate/skipped",

	// reports view
	_getReport: "api/v1/user/report",
	_getReportRecruiterAction: "api/v1/user/recruiterAction",
	_getSearchViewGraph: "api/v1/user/searchViewGraph",
	_getSearchAppearanceGraph: "api/v1/user/searchAppearanceGraph",
	_getReportViewlisting: "api/v1/user/viewlisting",

	// sub-category
	_getSubCategories:"recruiter/v1/common/getSubCategories",

	// promo
	_getPromo:"recruiter/v1/recruiter/paymentPlan/promoCode",
	_getAllCommon:"recruiter/v1/common/allCommon",
	_recruiterSearchOld:"recruiter/v1/recruiterSearch",
	_recruiterSearch:"recruiter/v1/recruiterSearch/userSearch",
	_saveSearch:"recruiter/v1/recruiterSearch/saveSearch",
	_saveSearchResult:"recruiter/v1/recruiterSearch/saveSearchResult",
	_getSaveSearch:"recruiter/v1/recruiterSearch/getSaveSearch", 
	_deleteSaveSearch:"recruiter/v1/recruiterSearch/deleteSaveSearch", 
	_autoCompleteSearch:"recruiter/v1/recruiterSearch/autocomplete", 
	_recurringPayment:"recruiter/v1/recruiter/recurring-payment", 
	_pauseAndResumePayment:"recruiter/v1/recruiter/paymentPlan/pauseAndResume", 
	_recruiterPermission:"recruiter/v1/recruiter/recruiterPermission",
	_recruiterChangePassword:"recruiter/v1/recruiter/profile/change-password",
	_deleteCard: "recruiter/v1/candidate/deleteCard",
	_updateCard: "recruiter/v1/candidate/updateCard",
	_billingAddress: "recruiter/v1/recruiter/billingAddress",
	_addBillingAddress: "recruiter/v1/recruiter/addBillingAddress",
	_cancelSubscription: "recruiter/v1/candidate/cancelSubscription",
	_getActiveSubscription: "recruiter/v1/recruiter/paymentPlan/getActiveSubscription",
	// template
	_getTemplate: "recruiter/v1/template",
	_getTemplateDetail: "recruiter/v1/template/detail",

	// Notification
	_recurringNotification: "recruiter/v1/recruiter/recurring-notification",

	// Acnt setting subscription/Inventory
	_transaction: "recruiter/v1/recruiter/paymentPlan/transaction",
	_inventoryHistory : "recruiter/v1/recruiter/paymentPlan/getActivePlanlistV1",
	_downloadInvoice : "recruiter/v1/template/downloadInvoice",

	// main landing page
	_landingHome:"api/v1/home/landingPageJobSeeker",
	_getLastCard:"recruiter/v1/candidate/getLastCard",
	_getNotificationListing:"recruiter/v1/recruiter/notificationListing",
	_getNotificationRead:"recruiter/v1/recruiter/notificationRead",
	_getNotificationDelete:"recruiter/v1/recruiter/notificationDelete",
	_getSearchUserDetails:"recruiter/v1/recruiter/jobpost/getUserDetails",
	_downloadRecruiterResume:"recruiter/v1/users/downloadResume",
	_forwardMail:"recruiter/v1/candidate/forwardMail",
	_toMailSend:"recruiter/v1/candidate/toMailSend",
	_recruiterDashboard:"recruiter/v1/dashboard",
	// report section
	_userReport:"recruiter/v1/report/userActivity",
	_searchDetail:"recruiter/v1/report/searchDetail",
	_inventoryUsage:"recruiter/v1/report/inventoryUsage",
	_inventoryUsageUser:"recruiter/v1/report/inventoryUsageUser",
	_jobPerformance:"recruiter/v1/report/jobPerformance",
	// jobseekre
	_jobApplylog:"api/v1/user/jobApplylog",
	_jobPerformanceTable:"recruiter/v1/report/jobPerformanceTable",
	_sixTeenPersonality:"recruiter/v1/recruiterSearch/sixTeenPersonality",
	_inventoryOnChat:"recruiter/v1/candidate/inventoryOnChat",
	// user chat api
	_userChatHistory:"api/v1/chat/chatHistory",
	_userClearChat:"api/v1/chat/clearChat",
	// recruiter chat api
	_recruiterChatHistory:"api/v1/recruiterChats/chatHistory",
	_recruiterClearChat:"api/v1/recruiterChats/clearChat",
	_deleteMsg:"api/v1/chat/deleteMessage",
	_recruiterDeleteMsg:"api/v1/recruiterChats/deleteMessage",
	_serverCurrentTime:"api/v1/chat/serverCurrentTime"
}
