import { Injectable } from "@angular/core";
import { AdminService } from "../admin.service";
import { apiUrl } from "src/app/global/global";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class UserDetailsService {

  public userDetails: any;
  public userPermission: any;
  public userId: any;
  private userDetailsSubject = new BehaviorSubject<any>(null);
  private userPermissionSubject = new BehaviorSubject<any>(null);
  userDetailsObserver: Observable<any> = this.userDetailsSubject.asObservable();
  userPermissionObserver: Observable<any> = this.userPermissionSubject.asObservable();

  constructor(private api: AdminService) {
    // this.api.getDatas(apiUrl._getUserInfo)
    //   .subscribe(async (res) => {
    //     this.userDetails = res.data;
    //     this.userDetailsSubject.next(res.data);
    //   });
  }

  getUserData() {
    this.api.getDatas(apiUrl._getUserInfo)
      .subscribe(async (res) => {
        // console.log(res);
        this.userDetails = res.data;
        this.userDetailsSubject.next(res.data);
      });
  }
  getRecruiterUserData() {
    this.userId = localStorage.getItem("userId")
    this.api.getDatas(apiUrl._getRecuriterDetail + "/" + this.userId)
      .subscribe(async (res) => {
        this.userDetails = res.data;
        this.userDetailsSubject.next(res.data);
        if (this.userDetails?.Recruiter?.userType == "SUB_RECRUITER") {
          this.getRecruiterPermission()
        }
      });
  }
  getRecruiterPermission() {
    this.userId = localStorage.getItem("userId")
    this.api.getDatas(apiUrl._recruiterPermission + "/" + this.userId)
      .subscribe(async (res) => {
        this.userPermission = res.data;
        this.userPermissionSubject.next(res.data);
      });
  }
}
