import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { MessagingService } from './services/messaging.service';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "HonorVet";
  message: any;
  constructor(private spinner: NgxSpinnerService, private messagingService: MessagingService,) {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage;
    if (environment.production) {
      console.log = function (): void { };
      console.debug = function (): void { };
      console.info = function (): void { };
      console.error = function (): void { };
      console.warn = function (): void { };
    }
  }
  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);
  }
  onActivate(event) {

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

  }
  ngOnInit() {
    this.messagingService.currentMessage.subscribe((message) => {
      console.log('Received message in app component:', message);
      // Handle redirection or other actions based on the message
      if (message) {
        console.log("avtar")
        // Example: Redirect to a specific route
        // this.router.navigate(['/specific-route']);
      }
    });
  }
  private handleNotificationClick(data: any) {
    // Extract information from notification data and navigate to the desired page
    const targetPage = data.targetPage || '/';
    // Perform the redirect
    // Example using Angular Router
    // this.router.navigate([targetPage]);
    //http://localhost:4200/dashboard
    // Replace this with your actual navigation logic

    //this.router.navigate(["dashboard-job"]);
    console.log('Notification clicked, navigating to:', targetPage);
  }
}
