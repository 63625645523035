import {Renderer2, Component, Inject,ElementRef, NgZone, OnInit, TemplateRef, ViewChild} from "@angular/core";
import { FormGroup,  FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ToastrService } from "ngx-toastr";
import * as CONSTANT from "../../services/constants";
import * as CryptoJS from "crypto-js";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MessagingService } from "../../services/messaging.service"
import { AngularFireMessaging} from "@angular/fire/compat/messaging"; 
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AuthApiService } from "src/app/services/social-account.service";
import { DOCUMENT } from '@angular/common';
@Component({ 
  selector: "app-login-emp",
  templateUrl: "./login-emp.component.html",
  styleUrls: ["./login-emp.component.css"]
})
export class LoginEmpComponent implements OnInit {
  modalRef?: BsModalRef;
  public form: FormGroup;
  showError = false;
  public loading = false;
  rememberMe = false;
  encryptedPassword: any;
  showPwd: boolean = false; 
  tokenFromUI = "0123456789123456";
  auth2: any;
  isPageLoaded = false;
  @ViewChild("loginRef", { static: true }) loginElement!: ElementRef;
  accessToken: any; 
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService: BsModalService, private renderer: Renderer2,private authAPI: AuthApiService,private formBuilder: FormBuilder,public route: ActivatedRoute, private router: Router,private api: AdminService,private toastrService: ToastrService, public zone: NgZone,private angularFireMessaging: AngularFireMessaging, private fcm: MessagingService,  private afAuth: AngularFireAuth) {
    this.form = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required,Validators.pattern(CONSTANT.EMAIL_REGEX)])],
      password: ["", Validators.compose([Validators.required])],
      // isAccepted:["",Validators.required],
    }); 
    this.accessToken = localStorage.getItem("accessToken");
  }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'hideMenuToggle');

    let data = localStorage.getItem("accessToken");
    if (data) {
      this.router.navigate(["/company-profile"]);
    } 
    window.localStorage.setItem("isPasswordSet","0")
    let rememberMe = localStorage.getItem("rememberMe");
    if (rememberMe == "1") {
      this.form.patchValue({
        email: localStorage.getItem("email"),
      });
      this.rememberMe = true;
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      this.rememberMe = false;
    } 
    this.getDeviceToken();
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("deviceToken");
    if ((deviceToken) && (deviceToken != "")) {
      return deviceToken;
    }
    else {
      this.fcm.requestPermission();
      deviceToken = localStorage.getItem("fireBaseToken")
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }
   // Encryption Functionality
   encryptUsingAES256(value) {
    return new Promise((resolve, reject) => {
      let ciphertext = CryptoJS.AES.encrypt(value, this.tokenFromUI);
      resolve(ciphertext);
    });
  }

  // Decryption Functionality
  decryptUsingAES256(value) {
    let bytes = CryptoJS.AES.decrypt(value.toString(), this.tokenFromUI);
    let plaintext = bytes.toString(CryptoJS.enc.Utf8);
    this.form.patchValue({
      password: plaintext,
    });
  }
 /******* Login Functionality ******/
 login() {
  
  if(this.form.value.isAccepted == false ){
    this.showError = true 
    setTimeout(() => {
      this.showError = false
    }, 3000);
    return
  }  
  if (this.form.valid) {
    this.loading = true; 
    const data = {
      "email": this.form.value.email,
      "password": this.form.value.password, 
      "deviceToken": this.getDeviceToken(),

    };
    this.api.postData(apiUrl._LoginEmp, data)
      .subscribe(async (res) => {
        this.loading = false
        localStorage.setItem("isAdminApproved", res.data.isAdminApproved.toString());
        localStorage.setItem("accessToken", res.data.accessToken)
        localStorage.setItem("userId", res.data.id)
        localStorage.setItem("isNotificationSetting", res.data.isNotificationSetting)
        localStorage.setItem("isNotificationSMSSetting", res.data.isNotificationSMSSetting) 
        this.toastrService.success("Logged in successfully.");
        if (this.rememberMe) {
          const encryptedPassword: any = await this.encryptUsingAES256(
            this.form.value.password
          );
          localStorage.setItem("rememberme", "1")
          localStorage.setItem("deviceToken",this.getDeviceToken())
        } else { 
        } 
        localStorage.setItem("login_type", "userLoggedIn");
        this.router.navigate(["/dashboard"])
        // if(res.data.isAdminApproved == "1" ){
        //   localStorage.setItem("login_type", "userLoggedIn");
        //   this.router.navigate(["/dashboard"]) 
        // }else{
        //   localStorage.setItem("login_type", "userNotApproved");
        //   this.router.navigate(["/edit-profile"])
        // }
        return false;
      },
        err => {
          this.toastrService.error("Error", err.error.message)
          this.loading = false
        })
  } else {
    this.showError = true;
    setTimeout(() => {
      this.showError = false;
    }, 3000);
  }
} 
open(template: TemplateRef<any>) {
  this.modalRef = this.modalService.show(
    template,
    Object.assign({}, { class: "gray registerModal" })
    );
}
}
