<div class="wrapper">
    <section class="terms_conditions">
        <div class="container">
            <div class="termsWrap">
                <!-- <h2>PRIVACY POLICY</h2> -->
                <p class="date">Last updated March 06, 2023</p>
                <ul>
                    <p>
                        This privacy notice for Technostaff, LLC (doing business as HonorVet Technologies) (<b>"HonorVet Technologies," "we," "us," or "our"</b>), describes how and why we might collect, store, use, and/or share
                        ("process") your information when you use our services ("Services"), such as when you:
                    </p>
                    <li>Visit our website at <a href="https://honorvets.com/"> https://honorvets.com/</a>, or any website of ours that links to this privacy notice</li>
                    <li>Download and use our mobile application (HonorVets), or any other application of ours that links to this privacy notice</li>
                    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                </ul>
                <p>
                    <b>Questions or concerns?</b> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you
                    still have any questions or concerns, please contact us at <a href="mailto:info@honorvettech.com"> info@honorvettech.com</a>
                </p>
                <h2 class="m-t-10">SUMMARY OF KEY POINTS</h2>

                <p>
                    <b>
                        <i>
                            This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below
                            to find the section you are looking for. You can also click here to go directly to our table of contents.
                        </i>
                    </b>
                </p>

                <p>
                    <b>What personal information do we process? </b>When you visit, use, or navigate our Services, we may process personal information depending on how you interact with HonorVet Technologies and the Services, the
                    choices you make, and the products and features you use. Click here to learn more.
                </p>

                <p><b>Do we process any sensitive personal information? </b>We do not process sensitive personal information.</p>

                <p>
                    <b>Do we receive any information from third parties?</b> We may receive information from public databases, marketing partners, social media platforms, and other outside sources.
                    <a href="">Click here to learn more.</a>
                </p>

                <p>
                    <b>How do we process your information? </b>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may
                    also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. <a href=""> Click here to learn more.</a>
                </p>

                <p><b>In what situations and with which parties do we share personal information?</b> We may share information in specific situations and with specific third parties.<a href="">Click here to learn more.</a></p>

                <p>
                    <b>How do we keep your information safe?</b> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or
                    information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and
                    improperly collect, access, steal, or modify your information. <a href=""> Click here to learn more.</a>
                </p>

                <p>
                    <b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.
                    <a href=""> Click here to learn more.</a>
                </p>

                <p>
                    <b>How do you exercise your rights?</b> The easiest way to exercise your rights is by filling out our data subject request form available here: honorvets.com/data-request, or by contacting us. We will consider
                    and act upon any request in accordance with applicable data protection laws.
                </p>

                <p>Want to learn more about what HonorVet Technologies does with any information we collect? <a href="">Click here to review the notice in full.</a></p>
                <h2 class="m-t-10">1. WHAT INFORMATION DO WE COLLECT?</h2>

                <h4 class="m-t-10">Personal information you disclose to us</h4>

                <p>
                    <i><b>In Short: </b>We collect personal information that you provide to us.</i>
                </p>

                <p>
                    We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in
                    activities on the Services, or otherwise when you contact us.
                </p>
                <p>
                    <b>Personal Information Provided by You.</b> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you
                    use. The personal information we collect may include the following:
                </p>

                <ul>
                    <li>names</li>
                    <li>phone numbers</li>
                    <li>email addresses</li>
                    <li>mailing addresses</li>
                    <li>job titles</li>
                    <li>usernames</li>
                    <li>passwords</li>
                    <li>contact preferences</li>
                    <li>contact or authentication data</li>
                    <li>billing addresses</li>
                    <li>debit/credit card numbers</li>
                </ul>
                <p><b>Sensitive Information.</b>We do not process sensitive information.</p>

                <p>
                    <b>Payment Data.</b> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment
                    data is stored by Stripe. You may find their privacy notice link(s) here: <a href="https://stripe.com/privacy."> https://stripe.com/privacy.</a>
                </p>

                <p>
                    <b>Social Media Login Data. </b>We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose
                    to register in this way, we will collect the information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.
                </p>
                <ul>
                    <p>Application Data. If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>
                    <li>
                        Geolocation Information. We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide
                        certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
                    </li>
                    <li>
                        Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's bluetooth, calendar, camera, contacts, microphone, reminders, sensors, sms
                        messages, social media accounts, storage, and other features. If you wish to change our access or permissions, you may do so in your device's settings.
                    </li>
                    <li>
                        Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and
                        application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our
                        application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile
                        device’s unique device ID, and information about the features of our application(s) you accessed.
                    </li>
                    <li>
                        Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may
                        turn them off in your device's settings.
                    </li>
                    <p>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>
                </ul>
                <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

                <h2 class="m-t-10">Information automatically collected</h2>

                <p>
                    <i><b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</i>
                </p>

                <p>
                    We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device
                    and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use
                    our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                </p>

                <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                <ul>
                    <p>The information we collect includes:</p>
                    <li>
                        Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log
                        files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the
                        date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports
                        (sometimes called "crash dumps"), and hardware settings).
                    </li>
                    <li>
                        Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include
                        information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system,
                        and system configuration information.
                    </li>
                    <li>
                        Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the
                        device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of
                        allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain
                        aspects of the Services.
                    </li>
                </ul>

                <h4 class="m-t-10">Information collected from other sources</h4>

                <p>
                    <i><b>In Short:</b>We may collect limited data from public databases, marketing partners, social media platforms, and other outside sources.</i>
                </p>

                <p>
                    In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing
                    partners, affiliate programs, data providers, social media platforms, and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user
                    behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion. If you interact with us on a social media
                    platform using your social media account (e.g., Facebook or Twitter), we receive personal information about you such as your name, email address, and gender. Any personal information that we collect from your
                    social media account depends on your social media account's privacy settings.
                </p>

                <h2 class="m-t-10">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>

                <p>
                    <i>
                        <b>In Short:</b>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your
                        information for other purposes with your consent.
                    </i>
                </p>
                <ul>
                    <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
                    <li>
                        <b>To facilitate account creation and authentication and otherwise manage user accounts.</b> We may process your information so you can create and log in to your account, as well as keep your account in
                        working order.
                    </li>
                    <li><b>To deliver and facilitate delivery of services to the user. </b>We may process your information to provide you with the requested service.</li>
                    <li><b>To respond to user inquiries/offer support to users.</b> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
                    <li><b>To send administrative information to you.</b> We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
                    <li><b>To fulfill and manage your orders.</b> We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</li>
                    <li><b>To enable user-to-user communications.</b> We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
                    <li><b>To request feedback.</b> We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
                    <li>
                        <b>To send you marketing and promotional communications.</b> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You
                        can opt out of our marketing emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below).
                    </li>
                    <li><b>To deliver targeted advertising to you.</b> We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.</li>
                    <li><b>To post testimonials.</b> We post testimonials on our Services that may contain personal information.</li>
                    <li><b>To protect our Services.</b> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
                    <li>
                        <b>To evaluate and improve our Services, products, marketing, and your experience.</b> We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of
                        our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.
                    </li>
                    <li><b>To identify usage trends.</b> We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
                    <li>
                        <b>To determine the effectiveness of our marketing and promotional campaigns.</b> We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant
                        to you.
                    </li>
                    <li><b>To comply with our legal obligations.</b> We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.</li>
                </ul>
                <h2 class="m-t-10">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>

                <p>
                    <i><b>In Short:</b>We may share information in specific situations described in this section and/or with the following third parties.</i>
                </p>
                <ul>
                    <p>We may need to share your personal information in the following situations:</p>
                    <li>
                        Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business
                        to another company.
                    </li>
                    <li>
                        When we use Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). To find out more about Google’s Privacy Policy, please refer to
                        this link. We obtain and store on your device ("cache") your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.
                    </li>
                    <li>Business Partners. We may share your information with our business partners to offer you certain products, services, or promotions.</li>
                </ul>
                <h2 class="m-t-10">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>

                <p>
                    <i><b>In Short:</b>We may use cookies and other tracking technologies to collect and store your information.</i>
                </p>

                <p>
                    We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is
                    set out in our Cookie Notice.
                </p>

                <h2 class="m-t-10">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>

                <p>
                    <i><b>In Short:</b> If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</i>
                </p>

                <p>
                    Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile
                    information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list,
                    and profile picture, as well as other information you choose to make public on such a social media platform.
                </p>

                <p>
                    We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are
                    not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your
                    personal information, and how you can set your privacy preferences on their sites and apps.
                </p>

                <h2 class="m-t-10">6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

                <p>
                    <i><b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</i>
                </p>

                <p>
                    We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax,
                    accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.
                </p>

                <p>
                    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal
                    information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                </p>

                <h2 class="m-t-10">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

                <p>
                    <i><b>In Short:</b>We aim to protect your personal information through a system of organizational and technical security measures.</i>
                </p>

                <p>
                    We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts
                    to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or
                    other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information,
                    transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                </p>

                <h2 class="m-t-10">8. DO WE COLLECT INFORMATION FROM MINORS?</h2>

                <p>
                    <i><b>In Short:</b> We do not knowingly collect data from or market to children under 18 years of age.</i>
                </p>

                <p>
                    We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent
                    to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to
                    promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at
                    <a href="mailto:privacy@honorvets.com.">privacy@honorvets.com.</a>
                </p>

                <h2 class="m-t-10">9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

                <p>
                    <i><b>In Short:</b> You may review, change, or terminate your account at any time.</i>
                </p>

                <p>
                    If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find
                    their contact details here:
                    <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html" target="_blank">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html.</a>
                </p>

                <p>
                    If you are located in Switzerland, the contact details for the data protection authorities are available here:
                    <a href=" https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank"> https://www.edoeb.admin.ch/edoeb/en/home.html.</a>
                </p>

                <p>
                    Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your
                    consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
                </p>

                <p>
                    However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in
                    reliance on lawful processing grounds other than consent.
                </p>

                <p>
                    Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying
                    "STOP" or "UNSUBSCRIBE" to the SMS messages that we send, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the
                    marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests,
                    or for other non-marketing purposes.
                </p>

                <h4 class="m-t-10">Account Information</h4>
                <ul>
                    <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                    <li>Log in to your account settings and update your user account.</li>
                    <p>
                        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud,
                        troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
                    </p>

                    <p>
                        Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to
                        remove cookies or reject cookies, this could affect certain features or services of our Services. To opt out of interest-based advertising by advertisers on our Services visit
                        <a href="http://www.aboutads.info/choices/" target="_blank">http://www.aboutads.info/choices/</a>.
                    </p>
                </ul>
                <p>If you have questions or comments about your privacy rights, you may email us at <a href="mailto:privacy@honorvets.com">privacy@honorvets.com.</a></p>

                <h2 class="m-t-10">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

                <p>
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online
                    browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser
                    signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that
                    practice in a revised version of this privacy notice.
                </p>

                <h2 class="m-t-10">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

                <p>
                    <i><b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</i>
                </p>

                <p>
                    California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about
                    categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the
                    immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                </p>

                <p>
                    If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request
                    removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the
                    data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).
                </p>

                <table>
                    <tbody>
                        <tr>
                            <th>Category</th>
                            <th>Examples</th>
                            <th>Collected</th>
                        </tr>

                        <tr>
                            <td>A. Identifiers</td>
                            <td>
                                Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name
                            </td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>B. Personal information categories listed in the California Customer Records statute</td>
                            <td>Name, contact information, education, employment, employment history, and financial information</td>
                            <td>NO</td>
                        </tr>

                        <tr>
                            <td>D. Commercial information</td>
                            <td>Transaction information, purchase history, financial details, and payment information</td>
                            <td>NO</td>
                        </tr>

                        <tr>
                            <td>E. Biometric information</td>
                            <td>Fingerprints and voiceprints</td>
                            <td>NO</td>
                        </tr>

                        <tr>
                            <td>F. Internet or other similar network activity</td>
                            <td>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
                            <td>NO</td>
                        </tr>

                        <tr>
                            <td>G. Geolocation data</td>
                            <td>Gender and date of birth</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
                            <td>Images and audio, video or call recordings created in connection with our business activities</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>I. Professional or employment-related information</td>
                            <td>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>J. Education Information</td>
                            <td>Student records and directory information</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>K. Inferences drawn from other personal information</td>
                            <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>L. Sensitive Personal Information</td>
                            <td></td>
                            <td>NO</td>
                        </tr>
                    </tbody>
                </table>

                <h4 class="m-t=10">CCPA Privacy Notice</h4>
                <ul>
                    <p>The California Code of Regulations defines a "resident" as:</p>
                    <li>(1) every individual who is in the State of California for other than a temporary or transitory purpose and</li>
                    <li>(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</li>
                </ul>
                <p>All other individuals are defined as "non-residents."</p>

                <p>If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.</p>

                <p><b>What categories of personal information do we collect?</b></p>

                <p>We have collected the following categories of personal information in the past twelve (12) months:</p>

                <ul>
                    <p>We will use and retain the collected personal information as needed to provide the Services or for:</p>
                    <li>Category A - As long as the user has an account with us</li>
                    <li>Category C - As long as the user has an account with us</li>
                    <li>Category K - As long as the user has an account with us</li>

                    <p>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</p>
                    <li>Receiving help through our customer support channels;</li>
                    <li>Participation in customer surveys or contests; and</li>
                    <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                </ul>
                <p><b>How do we use and share your personal information?</b></p>

                <p>More information about our data collection and sharing practices can be found in this privacy notice.</p>

                <p>You may contact us by email at <a href="mailto:ccpa@honorvets.com">ccpa@honorvets.com</a> , or by referring to the contact details at the bottom of this document.</p>

                <p>If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</p>

                <p><b>Will your information be shared with anyone else?</b></p>

                <p>
                    We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the
                    information on our behalf, following the same strict privacy protection obligations mandated by the CCPA.
                </p>

                <p>
                    We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your
                    personal information.
                </p>

                <p>
                    Technostaff, LLC has not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. Technostaff, LLC will not sell or share
                    personal information in the future belonging to website visitors, users, and other consumers.
                </p>

                <p><b>Your rights with respect to your personal data</b></p>

                <p class="underline">Right to request deletion of the data — Request to delete</p>

                <p>
                    You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions
                    provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be
                    required to protect against illegal activities.
                </p>

                <p class="underline">Right to be informed — Request to know</p>

                <ul>
                    <p>Depending on the circumstances, you have a right to know:</p>
                    <li>whether we collect and use your personal information;</li>
                    <li>the categories of personal information that we collect;</li>
                    <li>the purposes for which the collected personal information is used;</li>
                    <li>whether we sell or share personal information to third parties;</li>
                    <li>the categories of personal information that we sold, shared, or disclosed for a business purpose;</li>
                    <li>the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;</li>
                    <li>the business or commercial purpose for collecting, selling, or sharing personal information; and</li>
                    <li>the specific pieces of personal information we collected about you.</li>
                </ul>
                <p>
                    In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer
                    request.
                </p>

                <p class="underline">Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</p>

                <p>We will not discriminate against you if you exercise your privacy rights.</p>

                <p class="underline">Right to Limit Use and Disclosure of Sensitive Personal Information</p>

                <p>We do not process consumer's sensitive personal information.</p>

                <p class="underline">Verification process</p>

                <p>
                    Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide
                    information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match
                    the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other
                    verification methods as the circumstances dictate.
                </p>

                <p>
                    We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the
                    purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your
                    identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
                </p>

                <p class="underline">Other privacy rights</p>
                <ul>
                    <li>You may object to the processing of your personal information.</li>
                    <li>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</li>
                    <li>
                        You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act
                        on your behalf in accordance with the CCPA.
                    </li>
                    <li>
                        You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no
                        later than fifteen (15) days from the date of the request submission.
                    </li>
                    <p>
                        To exercise these rights, you can contact us by email at <a href="mailto:ccpa@honorvets.com"></a> , or by referring to the contact details at the bottom of this document. If you have a complaint about how we
                        handle your data, we would like to hear from you.
                    </p>
                </ul>

                <h2 class="m-t-10">12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

                <p>
                    <i><b>In Short:</b>Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.</i>
                </p>

                <p class="underline">Virginia CDPA Privacy Notice</p>

                <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>

                <p>"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.</p>

                <p>
                    "Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.
                </p>

                <p>"Sale of personal data" means the exchange of personal data for monetary consideration.</p>

                <p>If this definition "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.</p>
                <ul>
                    <p>The information we collect, use, and disclose about you will vary depending on how you interact with Technostaff, LLC and our Services. To find out more, please visit the following links:</p>
                    <li>Personal data we collect</li>
                    <li>How we use your personal data</li>
                    <li>When and with whom we share your personal data</li>
                    <p>Your rights with respect to your personal data</p>

                    <li>Right to be informed whether or not we are processing your personal data</li>
                    <li>Right to access your personal data</li>
                    <li>Right to correct inaccuracies in your personal data</li>
                    <li>Right to request deletion of your personal data</li>
                    <li>Right to obtain a copy of the personal data you previously shared with us</li>
                    <li>
                        Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant
                        effects ("profiling")
                    </li>
                </ul>
                <p>
                    Technostaff, LLC has not sold any personal data to third parties for business or commercial purposes. Technostaff, LLC will not sell personal data in the future belonging to website visitors, users, and other
                    consumers.
                </p>

                <p class="underline">Exercise your rights provided under the Virginia CDPA</p>

                <p>More information about our data collection and sharing practices can be found in this privacy notice.</p>

                <p>You may contact us by email at <a href="mailto:privacy@honorvets.com">privacy@honorvets.com</a> , by visiting honorvets.com/data-request, or by referring to the contact details at the bottom of this document.</p>

                <p>If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</p>

                <p class="underline">Verification process</p>

                <p>
                    We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorized agent, we may need to collect additional
                    information to verify your identity before processing your request.
                </p>

                <p>
                    Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when
                    reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.
                </p>

                <p class="underline">Right to appeal</p>

                <p>
                    If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at privacy@honorvets.com. Within sixty (60)
                    days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal if denied,
                    you may contact the Attorney General to <span class="blue">submit a complaint.</span>
                </p>

                <h2 class="m-t-10">13. DO WE MAKE UPDATES TO THIS NOTICE?</h2>

                <p>
                    <i><b>In Short:</b></i> Yes, we will update this notice as necessary to stay compliant with relevant laws.
                </p>

                <p>
                    We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material
                    changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be
                    informed of how we are protecting your information.
                </p>

                <h2 class="m-t-10">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>

                <p>If you have questions or comments about this notice, you may email us at <a href="mailto:privacy@honorvets.com"> privacy@honorvets.com</a> or by post to:</p>
                <address>
                    <b>Technostaff, LLC</b>
                    <br />
                    <b>271 Route 46 West</b>
                    <br />
                    <b>Suite C-202</b>
                    <br />
                    <b>Fairfield, NJ 07004</b>
                    <br />
                    <b>United States</b>
                </address>

                <h2 class="m-t-10">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>

                <p>
                    You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit:
                    honorvets.com/data-request.
                </p>
            </div>
        </div>
    </section>

    <footer id="footer">
        <div class="footer-top">
            <div class="container">
              <div class="row">
                <div class="col-lg-5 col-md-6 footer-contact">
                  <div class="logo">
                      <img src="../../../assets/images/LOGO.png" alt="HonorVet" class="img-fluid">
                  </div>
                  <div class="media">
                    <img src="../../../assets/images/icons/location.svg" alt="location">
                    <div class="media-body">
                      <p>
                        271 Route 46 West, Suite C-202, <br>
                        Fairfield NJ 07004 <br>
                      </p>  
                    </div>
                  </div>
                  <div class="media">
                    <img src="../../../assets/images/icons/call.svg" alt="call">
                  <div class="media-body">
                    <p><a href="tel:614-123-456-7890">(614) 123-456-7890</a></p>  
                  </div>
                </div>
                <div class="media">
                  <img src="../../../assets/images/ic_email.svg" alt="mail">      
                <div class="media-body">
                  <p><a href="mailto:info@honorvettech.com">info@honorvettech.com</a></p>
                </div>
              </div>    
            </div>  
            <div class="col-lg-5 col-md-6 footer-links">
                  <h4>Main links</h4>
                  <ul class="list-2 list-line">
                    <li><a href="javascript:void(0);" routerLink="/mobile-about">About</a></li>
                    <li><a href="javascript:void(0);" routerLink="/mobile-policy">Privacy policy</a></li>
                    <li><a href="javascript:void(0);"  routerLink="/employers">Employers</a></li>
                    <li><a href="javascript:void(0);" routerLink="/job-seekers">Job seekers</a></li>
                    <li><a href="javascript:void(0);" routerLink="/mobile-terms">Terms and Conditions</a></li>
                    <li><a href="javascript:void(0);" routerLink="/faq">FAQ's</a></li>        
                  </ul>
            </div>   
                <div class="col-lg-2 col-md-6 footer-info">
                  <h4>Find us on</h4>
                  <div class="social-links mt-3">
                    <a href="https://www.facebook.com/honorvettech/" class="facebook" target="_blank"><img src="../../../assets/images/icons/fb.svg"></a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FHonorVetTech" class="twitter" target="_blank"><img src="../../../assets/images/icons/twitter.svg"></a>
                    <a href="https://www.linkedin.com/company/honorvet-technologies/?_l=en_US" class="linkedin" target="_blank"><img src="../../../assets/images/icons/linkedin.svg"></a>
                    <a href="https://www.pinterest.com/honorvettechnologies/" class="pin" target="_blank"><img src="../../../assets/images/icons/pin.svg"></a>
                    <a href="https://www.instagram.com/honorvettech/" class="pin" target="_blank"><img src="../../../assets/images/instagram.svg"></a>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div class="copyright">
            © 2023. <strong><span> All Rights Reserved HonorVet Technologies</span></strong>
          </div>  
    </footer>
</div>