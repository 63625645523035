import { Component, OnInit,ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute, Params} from "@angular/router";
import { DataSharingService } from "../services/data.share.service";

@Component({
  selector: "app-internal",
  templateUrl: "./internal.component.html",
  styleUrls: ["./internal.component.css"],
})
export class InternalComponent implements OnInit {
  title = "Honorvet";
  urlnumber;
  sub2;
  
  public queryParams;
  ishidesidebar = false
  isActive: boolean | undefined; 
  routerUrl: string;
  constructor(private dataSharingService: DataSharingService,private activeRoute: ActivatedRoute,private _router:Router,private cdf:ChangeDetectorRef){
    this.routerUrl = _router.url;

}
  ngOnInit(): void {}/**
  * Check if the router url contains the specified route
  *
  * @param {string} route
  * @returns
  * @memberof MyComponent
  */
 hasRoute(route: string) {
   // console.log("this.router",route)
   return this._router.url.includes(route);
}
}
