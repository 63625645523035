import { Injectable} from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs/index";

@Injectable({
  providedIn: "root"
})
export class Header2Service {
  profileUpdateCount = 0;
  userName = 0;
  private title = new BehaviorSubject<String>("App title");
  private title$ = this.title.asObservable();  
  private search = new BehaviorSubject<String>("");
  private search$ = this.search.asObservable(); 
  private profileUpdated = new BehaviorSubject<Number>(this.profileUpdateCount);
  private profileUpdated$ = this.profileUpdated.asObservable(); 
  private cusName = new BehaviorSubject<Number>(this.userName);
  private cusName$ = this.cusName.asObservable();

  // private newNotificationUpdated = new BehaviorSubject<Number>(this.profileUpdateCount);
  // private newNotificationUpdated$ = this.newNotificationUpdated.asObservable(); 

  
  
  constructor() {}
 
  setTitle(title: String) {
    this.search.next("")
    this.title.next(title);
  } 
  getTitle(): Observable<String> {
    return this.title$;
  }

  setSearch(search: String) {
    this.search.next(search)
  }
  getSearch() : Observable<String> {
    return this.search$;
  }

  getNameUpdated() : Observable<Number> {
    return this.cusName$;
  }
  setNameUpdated() {
    this.cusName.next(this.userName)
  }
  getProfileUpdated() : Observable<Number> {
    return this.profileUpdated$;
  }

  setProfileUpdated() {
    this.profileUpdated.next(++this.profileUpdateCount)
  }
  
  // getNewnotification(){
  //   this.newNotificationUpdated.next(true)
  // }
  
}
