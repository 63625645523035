export const environment = {
  apiUrl:"https://lc0ekgayo5.execute-api.us-east-1.amazonaws.com/dev/",
  
  // apiUrl:"https://w3jke9psob.execute-api.us-east-1.amazonaws.com/stage/",
  // apiUrl:"https://api.honorvets.com/",
  production: false,
  stripe: {
    // stage
    //publicKey: "pk_test_51MhDUDFGzVjaUNdauwkHavFTPm6JLl1AStYVKzLM8BFz93SHSNheMPRdaPFrLIVSnjBY9KyRRfRYpW4VwGUwOpzk00sRDA57Nw",
    // live
    publicKey: "pk_live_51MhDUDFGzVjaUNdakIyzHtFEmARx5iFsCLh51MSsNXMoaYAsTDpzcYwQldD8Igk088CjIcQKEQPK00cHEyutwMJ200RyNsFE4E",
  },
  firebase: {
    apiKey: "AIzaSyAU-cMiU58AdE_xQRk1N69Dy6S0VxPYKMo",
    authDomain: "honorvet-cb626.firebaseapp.com",
    databaseURL: "https://honorvet-cb626-default-rtdb.firebaseio.com",
    projectId: "honorvet-cb626",
    storageBucket: "honorvet-cb626.appspot.com",
    messagingSenderId: "1044983158863",
    appId: "1:1044983158863:web:35b1bde9ba85f0ead5f23a",
    measurementId: "G-PVC4ZG1HX9"
  }
};