import { Component, ElementRef, NgZone, OnInit, TemplateRef, ViewChild} from "@angular/core";
import { FormGroup,  FormBuilder, Validators } from "@angular/forms";
import { SharedService } from "../shared.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ToastrService } from "ngx-toastr";
import * as CONSTANT from "../../services/constants";
import * as CryptoJS from "crypto-js";

@Component({
  selector: "app-choose-yourself",
  templateUrl: "./choose-yourself.component.html",
  styleUrls: ["./choose-yourself.component.css"]
})
export class ChooseYourselfComponent implements OnInit {
  showError = false;
  public registerForm: FormGroup;
  userType;
  public loading = false;
  deviceToken;
  constructor(private formBuilder: FormBuilder,public route: ActivatedRoute, private router: Router,private api: AdminService,private toastrService: ToastrService,private sharedService: SharedService) {
    this.registerForm = this.formBuilder.group({
      veteranRelationName: ["", Validators.compose([Validators.required])],
    }); 
   }

  ngOnInit(): void {
    // this.userType = this.sharedService.userType; 
    window.localStorage.setItem("isPasswordSet",'0') 
    this.userType =localStorage.getItem("userType");  
    this.deviceToken = localStorage.getItem("deviceToken");
  }
  
  onSubmit(){
    if(this.registerForm.valid){
      const veteranRelationName = this.registerForm.get("veteranRelationName")?.value;
      localStorage.setItem("veteranRelationName", veteranRelationName)
      this.router.navigate(["register"]); 
    } else{
      this.showError = true;
      setTimeout(() => {
        this.showError=false
      }, 2000);
    }
  }
}
