<div class="page-wrapper innerWrapper innerPages">
  <!-- class add = innerPages -->
  <app-header2></app-header2>
  <app-emp-sidebar></app-emp-sidebar>
  <div class=" recruiter-panel">
    <!-- class remove = pd-tb-39 -->
      <div class="fade-in-up">
          <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
  </div>
</div>