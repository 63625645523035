import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AdminService } from './admin.service';
import { apiUrl } from "../global/global";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthApiService {

  UserData: any;

  userDataSub: BehaviorSubject<any> = new BehaviorSubject(null);

  Images = [
    { _id: '1', photo: '../../../assets/images/Web_Avatar/Avatar_1.png' },
    { _id: '2', photo: '../../../assets/images/Web_Avatar/Avatar_2.png' },
    { _id: '3', photo: '../../../assets/images/Web_Avatar/Avatar_3.png' },
    { _id: '4', photo: '../../../assets/images/Web_Avatar/Avatar_4.png' } 
  ]

  constructor(private http: HttpClient, public router: Router,
    private adminSrvc: AdminService) {
    let data = JSON.parse(localStorage.getItem('UserData')!);
    this.userDataSub.next(data);
  }

  authenticateUser(email: string, password: string) {
    let deviceToken = localStorage.getItem("deviceToken");
    return this.adminSrvc.upateData(apiUrl._socialLogin, { email, password, deviceToken });
  }

  updateUser(userInfo: any) {
    // let userData = new FormData();
    // // console.log(uploadShowData);
    // userData.append('uploadType', userInfo.uploadType);
    // userData.append('title', userInfo.title);
    return this.adminSrvc.upateData(apiUrl._updateProfile, userInfo);
  } 
  VerifyGoogleLogin(loginData: any) {
    
    let deviceToken = localStorage.getItem("deviceToken");
    loginData.deviceToken = deviceToken;
    return this.adminSrvc.postData(apiUrl._socialLogin, loginData);
  }

  PresenterSignUp(data: any) {
    let deviceToken = localStorage.getItem("deviceToken");
    data.deviceToken = deviceToken;
    return this.adminSrvc.postData(apiUrl._userRegister, data);
  }

  resendOTP(data: any) {
    return this.adminSrvc.postData(apiUrl._otpResend, data);
  }

  SaveAuthData(UserData: any, token: any) {
    this.SaveLoginToken(token);
    this.SaveLoginUser(UserData);
  }

  SaveLoginToken(token: any) {
    localStorage.setItem('WEBToken', token);
  }

  SaveLoginUser(UserData: any) {
    this.userDataSub.next(UserData);
    localStorage.setItem('UserData', JSON.stringify(UserData));
  }

  GetLoginToken() {
    return localStorage.getItem('WEBToken');
  }

  GetLoggedInUser() {
    return JSON.parse(localStorage.getItem('UserData')!);
  }

  UpdateUser(userData: any) {
    console.log(userData);
    this.userDataSub.next(userData);
    // Get back item "Users" from local storage
    var userFromLocalStorage = this.GetLoggedInUser();
    // Change value
    userFromLocalStorage.name = userData.name;
    userFromLocalStorage.email = userData.email;
    userFromLocalStorage.phoneNumber = userData.phoneNumber;
    userFromLocalStorage.profilePic = userData.profilePic;
    // Save the new item with updated value
    localStorage.setItem('UserData', JSON.stringify(userFromLocalStorage));
  }
  logout() {
    return this.adminSrvc.upateData(apiUrl._logout, {});
  }

  forgotPassword(email: string) {
    return this.adminSrvc.upateData(apiUrl._forgotPassword, { email });
  }

  changePassword(data: any) {
    return this.adminSrvc.upateData(apiUrl._changePassword, data);
  }
  
  getDataUser(url: any) {
    return this.adminSrvc.getData(url,{});
  }

  RemoveAuthData() {
    this.RemoveLoginToken();
    this.RemoveLoggedInUser();
  }


  RemoveLoginToken() {
    localStorage.removeItem('WEBToken');
  }

  RemoveLoggedInUser() {
    localStorage.removeItem('UserData');
  }

  getlocation(){
    return this.http.get<any>('https://ipapi.co/json/');
  }
}