<header id="header" class="d-flex align-items-center fixed-top ">
  <div class="container d-flex align-items-center justify-content-lg-between headerContainer">

    <div class="logo d-flex align-items-center">
      <a href="javascript:void(0);" routerLink="/home">
        <!-- <img src="../../../assets/images/logo.svg" alt="HonorVet" class="img-fluid logo d-flex align-items-center  me-auto me-lg-0"> -->
        <img src="../../../assets/images/LOGO1.png" alt="HonorVet"
          class="img-fluid logo d-flex align-items-center  me-auto me-lg-0">
      </a>

      <div class="search-bar" *ngIf="showHead">
        <form class="search-form d-flex align-items-center">
          <input (ngModelChange)="onTextChange($event)" class="search" type="text" [(ngModel)]="search" name="query"
            placeholder="Find your dream job…" title="Enter search keyword">
          <button [disabled]="!closeSearch" (click)="searchFun()" type="submit" title=""><img
              src="../../../assets/images/search.svg"></button>
          <div class="cross_icon" (click)="refreshPage()" *ngIf="closeSearch">
            <img src="../../../assets/images/ic_cancel_search.svg">
          </div> 
        </form> 
      </div>
    </div>
  
    <nav  class="navbar justify-content-between align-items-center ord-flexder-last order-lg-0 li-center" 
      *ngIf="showHead">
   
      <div class="menu" [class.menuClasse]="menuVariable"  >
        <ul class="list-new">
          <li><a href="javascript:void(0);" routerLink="/about" routerLinkActive="active">About</a></li>
          <li><a href="javascript:void(0);" routerLink="/employers" routerLinkActive="active">Employers</a></li>
          <li><a href="javascript:void(0);" routerLink="/job-seekers" routerLinkActive="active">Job Seekers</a></li>
        </ul>
      </div>
    </nav>
 
    <div class="li-right" *ngIf="showHead">
      <ul class="list">
        <li> <a class="borderBtn" data-toggle="modal" data-target="#verify" (click)="redirectTo($event,0)">Jobseeker
            Login</a></li>
        <li> <a class="orangeBtn" data-toggle="modal" data-target="#myModal2" (click)="redirectTo($event,1)">Employer
            Login</a></li>
      </ul>
    </div>
    <!-- Mobile Menu -->
    
  </div>
  <a class="menu_icon"  [class.menu_iconClass]="menu_icon_variable" (click)="openMenu()">   <span></span>
    <span></span>
    <span></span></a>
</header>