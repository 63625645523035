import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AdminService } from "./admin.service";
import { SharedService } from '../extrnal/shared.service';
@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  constructor(private admin: AdminService,private sharedService: SharedService) {}
  shouldHandleErrorByOwn = false
  intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    //let token = "Bearer "+ localStorage.getItem("accessToken");
    if (req?.body?.hasOwnProperty('handleErrorMessage')) {
      this.shouldHandleErrorByOwn = req.body['handleErrorMessage'] == true
      delete req.body['handleErrorMessage']
    }
     // const token = localStorage.getItem("accessToken")
    //   ? localStorage.getItem("accessToken")
    //   : "";
    // let noToken = localStorage.getItem("noToken")
    //   ? localStorage.getItem("noToken")
    //   : "";
    let token = localStorage.getItem("accessToken"); 
    if (!req?.url.includes('amazonaws.com/download')){
    if(token)
    {
      req = req.clone({ headers: req.headers.set("authorization", token)});
    }  
    if (token) {
      req = req.clone({ headers: req.headers.set("authorization","Bearer "+ token) });
    } else if (this.sharedService.accessToken) {
      req = req.clone({ headers: req.headers.set("authorization","Bearer "+ this.sharedService.accessToken) });
    }
}
    if (this.admin.showLoader) {
      this.admin.loader(true);
    } 
    else {this.admin.showLoader = true;}
   
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.admin.loader(false);
          }
        },
        (err: any) => {
          this.admin.loader(false);
          if(!req?.url.includes('restricted')) {
          if (err instanceof HttpErrorResponse) {
            if (err.error.statusCode == 401)
              this.admin.errorAlertApi(err.error.message, true);
            // else
            //   if (err.error.statusCode == 402)
            //     this.admin.errorAlert(err.error.message, true);
            //   else
            //     if (err.error.statusCode == 403)
            //       this.admin.errorAlert(err.error.message, true);
            else this.admin.errorAlertApi(err.error.message, false);
          }
        }
        }
      )
    );
  }
}
