import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";

import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs"
import { Router } from '@angular/router';


import * as firebase from 'firebase/app';
import 'firebase/messaging';

export declare function isWindowSupported(): Promise<boolean>;
@Injectable({
  providedIn: "root"
})
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  fireBase = new BehaviorSubject(null)
  checkNotification = new BehaviorSubject(null);
  checkMessageNotification = new BehaviorSubject(null);


  constructor(private angularFireMessaging: AngularFireMessaging,
    private db: AngularFireDatabase,private router: Router,
    private angularFireAuth: AngularFireAuth, private toastr: ToastrService) {

    this.angularFireMessaging.onMessage((payload) =>{
      console.log('payload', payload)
      this.currentMessage.next(payload);
      
    })

    this.angularFireMessaging.messages.subscribe((msg) => {
       this.showCustomNotification(msg);
      console.log("msg", msg)
       if (msg.notification) {
        // Handle the foreground notification
        this.handleNotificationClick(msg?.notification);
      }
      
    })

   

  }
  handleNotificationClick(msg){

  }
  requestPermission() {
    var promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.angularFireMessaging.requestToken.subscribe(
          (token) => {
            localStorage.setItem("deviceToken", token || "")
            // return token;
            return resolve(token);
          },
          (err) => {
            console.error("Unable to get permission to notify.", err);
          }
        );
      }, 1000);
    });
    return promise;
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {      
        if (payload.data.title == "") {
          this.toastr.info(payload.data.message)
        } else {
          this.toastr.success(payload.data.message)
        }
        let data = payload.data
        console.log("payload", payload)
        this.currentMessage.next(payload);
        this.showCustomNotification(payload);
      })
  }

  
  showCustomNotification(payload: any) {
    console.log(payload,'registration');
    let notify_data = payload['notification'];
    let title = notify_data['title'];
    let options = {
      body: title,
    }
    const NotificationOptions = {
      title: payload.notification.title,
      body: payload.notification.body,
      data: payload.data,
      icon: payload.notification.icon
    }
    navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
     // console.log('registration==========', registration,);
      registration?.showNotification(title, options);
    });
  }


}