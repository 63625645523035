import { AfterContentInit, AfterViewInit,Renderer2,Inject, Component, ElementRef, NgZone, OnInit, TemplateRef, ViewChild} from "@angular/core";
import { FormGroup,  FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ToastrService } from "ngx-toastr";
import * as CONSTANT from "../../services/constants";
import * as CryptoJS from "crypto-js";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AngularFireMessaging} from "@angular/fire/compat/messaging"; 
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { MessagingService } from "src/app/services/messaging.service";
import { AuthApiService } from "src/app/services/social-account.service";
import { SharedService } from "src/app/extrnal/shared.service";
import { Subject } from "rxjs";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: "app-create-account",
  templateUrl: "./create-account.component.html",
  styleUrls: ["./create-account.component.css"]
})
export class CreateAccountComponent implements OnInit {
  modalRef?: BsModalRef;
  public form: FormGroup;
  showError = false;
  public loading = false;
  userType: number | undefined;
  registerData;
  // social log
  
  auth2: any;
  isPageLoaded = false;
  @ViewChild("loginRef", { static: true }) loginElement!: ElementRef;
  name:any;
  email:any;
  userInfoObserver = new Subject<string>();
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private modalService: BsModalService,private formBuilder: FormBuilder,private authAPI: AuthApiService,public route: ActivatedRoute, private router: Router,private sharedService: SharedService,private api: AdminService,private toastrService: ToastrService, public zone: NgZone, private angularFireMessaging: AngularFireMessaging, private fcm: MessagingService,  private afAuth: AngularFireAuth) {
    this.form = this.formBuilder.group({
      name: ["", Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(30)])],
      email: ["", Validators.compose([Validators.required,Validators.pattern(CONSTANT.EMAIL_REGEX)])],
      deviceToken: [""],
      isAccepted:["1",Validators.required],
    }); 
  }
  ngOnInit(): void { 
    this.renderer.addClass(this.document.body, 'hideMenuToggle');
    let data = localStorage.getItem("deviceToken");  
    this.route.queryParams.subscribe(params => {
      if (params['name']){
      this.name = params['name'];  
    }
      if (params['email']){
      this.email = params['email'];  
    }
    this.getUserInfo()
    });
    this.getDeviceToken();
  } 
   
  getDeviceToken() {
    let deviceToken = localStorage.getItem("deviceToken");
    if ((deviceToken) && (deviceToken != "")) {
      return deviceToken;
    }
    else {
      this.fcm.requestPermission();
      deviceToken = localStorage.getItem("fireBaseToken")
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  } 
  getUserInfo(){
    this.form.patchValue({
      name: this.name,
      email: this.email,
    })
  }
  // register
  signUp() {
    
    if(this.form.value.isAccepted == false ){
      this.showError = true 
      setTimeout(() => {
        this.showError = false
      }, 3000);
      return
    }  
    let fullName = this.form.value.name.trim();
    this.form.patchValue({
      name: fullName,
      
    })
    if (this.form.valid) {
      this.loading = true; 
      const data = {
        "name": this.form.value.name.trim(),
        "email": this.form.value.email,  
        "deviceToken": this.getDeviceToken(),
      };
      this.api.postData(apiUrl._registerEmp, data)
        .subscribe(async (res) => {
          this.registerData=res
          this.loading = false  
          if(res.data.accessToken){
            const accessToken = res.data.accessToken;
            this.sharedService.accessToken = accessToken;
          } 
          localStorage.setItem("userId", res.data.id) 
          window.localStorage.setItem("userDetail", JSON.stringify(res.data));
          window.localStorage.setItem("userData", JSON.stringify(res.data));
          localStorage.setItem("s3Folders", JSON.stringify(res.data.s3Folders));
          let queryparams = {}
          queryparams["name"] = res.data?.name; 
          queryparams["email"] = res.data?.email; 
          this.router.navigate(["/otp"], {queryParams : queryparams})
          // this.router.navigate(["/otp"]);
        },
          err => {
            this.toastrService.error("Error", err.error.message)
            this.loading = false
          })
    } else {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 3000);
    }
  } 
  // 
}

