import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SharedService {
  userType: number | undefined;
  accessToken: any;
  constructor() { }
}
