import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataSharingService {
  public isLoginChanged: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isProfileChanged: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isPermissionChanged: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public updateCount: BehaviorSubject<boolean> =new BehaviorSubject<boolean>(false);
  public userAvatar: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public searchParam: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public searchEmpty: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  getCurrentUser() {
    return localStorage.getItem("userName");
  }

  getUserType() {
    return localStorage.getItem("userType");
  }

  setUserAvatar(value) {
    localStorage.setItem("userAvatar", value);
    this.userAvatar.next(value);
  }

  searchFaq(query: string) {
    console.log("query------", query);
    this.searchParam.next(query);
  }
}
