import { Component, OnInit } from "@angular/core";
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import swal from "sweetalert2";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ToastrService } from "ngx-toastr";
import * as CONSTANT from "../../services/constants";
import { UsernameValidator } from "../../services/NoWhiteSpace.validator";
import { SharedService } from "src/app/extrnal/shared.service";

@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.css"]
})
export class PasswordComponent implements OnInit {
  upper = 0;
  lower = 0;
  number = 0;
  special = 0;
  notAllow = 0;
  loader: boolean = false;
  showPwd: boolean = false; 
  showError: boolean = false;
  SignUpPwdValid: boolean = false;
  public form: FormGroup;
  password: string = "";
  userData: any;
  accessToken: any; 
  constructor(private formBuilder: FormBuilder, private toastrService: ToastrService, private router: Router, private api: AdminService,private sharedService: SharedService) {
    this.form = this.formBuilder.group({
      password: ["", Validators.compose([Validators.required, Validators.minLength(8)])],
    });
    this.accessToken = localStorage.getItem("accessToken");
  }

  ngOnInit(): void {
    this.userData= JSON.parse(window.localStorage.getItem("userDetail")!); 
    let data = localStorage.getItem("accessToken");
    this.accessToken = this.sharedService.accessToken;    
    if (data) {
      this.router.navigate(["/company-profile"]);
    } 
  }

  setPassword(){
    if (this.form.valid && this.form.value.password && this.SignUpPwdValid && !this.form.value.password.includes(" ")) {
      this.showError = false;
      this.loader = true;
      const data = {
        "password": this.form.value.password, 
      };
      this.api.putData(apiUrl._setUserPassword, this.form.value)
        .subscribe(res => {
          this.loader = false;
          window.localStorage.setItem("isPasswordSet","1") 
          localStorage.setItem("accessToken",this.accessToken);
          localStorage.setItem("login_type", "employer");
          this.toastrService.success("", "Password Set Successfully!");
          this.router.navigate(["/company-profile"]);
          this.password = "";
        },
          err => {
            this.toastrService.error("Error", err.error.message)
            this.loader = false;
          });
    } else {
      this.showError = true;
    }
  }
  keyup(event) {
      var str = this.form.value.password
      this.upper = 0,
        this.lower = 0,
        this.number = 0,
        this.special = 0,
        this.notAllow = 0;
      for (var i = 0; i < str.length; i++) {
        if (str[i] >= "A" && str[i] <= "Z") this.upper++;
        else if (str[i] == "<" || str[i] == ">") this.notAllow++;
        else if (str[i] >= "a" && str[i] <= "z") this.lower++;
        else if (str[i] >= "0" && str[i] <= "9") this.number++;
        else this.special++;
      }
      if (this.form.value.password.length > 7 && this.upper != 0 && this.lower != 0 && this.number != 0 && this.special != 0 && this.notAllow == 0) {
        this.SignUpPwdValid = true
       
      } else {
        this.SignUpPwdValid = false
      }
    }
}
