import { Component, OnInit } from "@angular/core";
import { UserDetailsService } from "src/app/services/user/user-details.service";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { chatEnvironment } from "src/environments/chatEnvironment";
import { number } from "echarts";
import { DataSharingService } from "src/app/services/data.share.service";
@Component({
  selector: "app-emp-sidebar",
  templateUrl: "./emp-sidebar.component.html",
  styleUrls: ["./emp-sidebar.component.css"]
})
export class EmpSidebarComponent implements OnInit {
  public userDatas: any;
  msgCount = 0;
  allJobs: any = [];
  firebaseChats = chatEnvironment.chats;
  screenSize: any;
  countArray: any = [];
  public userPermissionData: any;
  constructor(private router: Router, private dataSharingService: DataSharingService, public route: ActivatedRoute, private db: AngularFireDatabase, private api: AdminService, private userDetailsService: UserDetailsService) {
    this.userDetailsService.getRecruiterUserData();
    this.userDetailsService.userDetailsObserver.subscribe(userData => {
      this.userDatas = userData;
    });
    this.userDetailsService.getRecruiterPermission();
    this.userDetailsService.userPermissionObserver.subscribe(res => {
      this.userPermissionData = res;
    });
    this.dataSharingService.updateCount.subscribe(value => {
      if (value) {
        this.countArray=[];
        this.getAllJobs();
      }

    });
  }

  ngOnInit(): void {
    this.getAllJobs();
    this.screenSize = window.innerWidth;

  }
  closeMenuBar() {
    document.querySelector('body')?.classList.remove('sidebar-mini');
    // if (this.screenSize <= 1200) {

    // }
  }

  async getAllJobs() {
    this.allJobs = this.api.getJobsData();
   
    if (this.allJobs?.length > 0) {
      this.checkMessage(this.allJobs)

    } else {
      this.allJobs = await this.api.getDataAPI();
      if (this.allJobs?.length > 0) {
        this.checkMessage(this.allJobs)
      }
    }
    console.log("this.allJobs", this.allJobs)
  }
  checkMessage(data) {
    for (let i = 0; i < data.length; i++) {
      this.getJobDialogs(data[i].id, i)
    }
  }

  getJobDialogs(job_id, type) {

    this.db.list(`${this.firebaseChats}`,
      query => query
        .orderByChild("job_id")
        .equalTo(job_id)
    ).valueChanges().subscribe(listNew => {
      let dialogListing = Object.assign([], listNew);
      if (dialogListing?.length > 0) {
        let filterData = dialogListing.filter(data => {
       
          if (data['unread_count'][job_id] > 0) {
            console.log("foundObject3", job_id)
            if (this.countArray.length > 0) {
              const foundObject = this.countArray.find(obj => obj.job_id == job_id);
              console.log("foundObject", foundObject)
              if(foundObject){
              
              }else{
                this.countArray.push({ "unreadCount": "1", "job_id": job_id })
              }
            } else {
              console.log("foundObject2")
              this.countArray.push({ "unreadCount": "1", "job_id": job_id })
            }
          }
        });
      }
    });
  }
}
