import { OnChanges, Component, OnInit, TemplateRef } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { DataSharingService } from "../../services/data.share.service";
import swal from "sweetalert2";
import { HttpParams } from "@angular/common/http";
import { ImageUrls } from "src/app/services/s3buket";
import { HeaderService } from "./header.service"
import { MessagingService } from "src/app/services/messaging.service";
import { UserDetailsService } from "src/app/services/user/user-details.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnChanges, OnInit {
  toggleSidebar = false;
  userData: any;
  globalUrl = ImageUrls.BaseUrl + ImageUrls.directories.users
  showHead: boolean = false;
  showLogout: boolean = false;
  profileImage;
  userDatas;
  deviceToken: any;
  constructor(private router: Router, public route: ActivatedRoute, private api: AdminService, private dataSharingService: DataSharingService, private headerService: HeaderService, private fcm: MessagingService, private userDetailsService: UserDetailsService) {
    this.userDetailsService.getUserData();
    this.userDetailsService.userDetailsObserver.subscribe(userData => {
      this.userDatas = userData;
    });
    this.dataSharingService.isProfileChanged.subscribe(value => {
      this.userDatas = localStorage.getItem('userData')
      if (!(localStorage.getItem('userData') == 'null' || localStorage.getItem('userData') == null)) {
        this.userDatas = JSON.parse(localStorage.getItem("userData")!!.toString());
      }
    });
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // if (val.url.endsWith("/home")){
        if (val.urlAfterRedirects.endsWith("/home")) {
          this.showHead = true;
          this.showLogout = false;
        } else if (this.router.url.endsWith("/upload-document") || this.router.url.endsWith("/complete-profile")) {
          this.showLogout = true;
        }
        else {
          this.showLogout = false;
          this.showHead = false;
        }
      }
    })
  }
  ngOnChanges() {
    this.userDetailsService.getUserData();
    this.userDetailsService.userDetailsObserver.subscribe(userData => {
      this.userDatas = userData;
    });
  }
  ngOnInit(): void {
    this.deviceToken = localStorage.getItem("deviceToken")
  }

  getDeviceToken() {
    let deviceToken = localStorage.getItem("deviceToken");
    if ((deviceToken) && (deviceToken != "")) {
      return deviceToken;
    }
    else {
      this.fcm.requestPermission();
      deviceToken = localStorage.getItem("fireBaseToken")
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }

  toggleNavbar(event: any) {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("sidebar-mini");
    if ((this.toggleSidebar = !this.toggleSidebar)) {
    } else {
      body.classList.remove("sidebar-mini");
    }
  }
  getUserDetail() {
    this.api.getDatas(apiUrl._getUserInfo)
      .subscribe(async (res) => {
        this.userDatas = res.data;
        this.profileImage = this.globalUrl + res.data.profileImage
      })
  }

  logout() {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      swal.fire({
        html: '<p class="gilroy-bold">Are you sure you want to Logout?</p>',
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        customClass:  {
          popup:"post-popup logout-pop"},
        confirmButtonAriaLabel: "Yes",
        cancelButtonAriaLabel: "Cancel",
        confirmButtonColor: "#FE831D",
        cancelButtonColor: "#5A50CC",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: true,
        width: 500,

      }).then((result) => {
        if (result.value) {
          this.clearLocalStorage()
          // const data={
          //   "deviceToken":this.deviceToken
          // }
          // this.api.putData(apiUrl._logout,data).subscribe(
          //   res => {

          // });
        }
        //   else if(result.isDismissed == true){
        //  }
      })
    } else {
      this.clearLocalStorage()
    }
  }
  clearLocalStorage() {
    window.localStorage.clear();
    localStorage.clear()
    localStorage.removeItem("accessToken");
    localStorage.removeItem("deviceToken");
    localStorage.removeItem("isPasswordSet");
    localStorage.removeItem("login_type");
    localStorage.removeItem("userType");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("s3Folders");
    this.router.navigate(["/home"]);
  }
}
