import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-recruiter-components",
  templateUrl: "./recruiter-components.component.html",
  styleUrls: ["./recruiter-components.component.css"]
})
export class RecruiterComponentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onActivate(event) {
 
    window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
     });
 
 }
}
