<!-- Footer -->
<footer id="footer">
  <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-6 footer-contact">
            <div class="logo">
              <!-- <a href="javascript:void(0)"  routerLink="/home"> -->
                <!-- <img src="../../../assets/images/brands/ic_footer_full_logo.svg" alt="HonorVet" class="img-fluid"> -->
                <img src="../../../assets/images/LOGO.png" alt="HonorVet" class="img-fluid">
              <!-- </a> -->
            </div>
            <div class="media">
              <img src="../../../assets/images/icons/location.svg" alt="location">
              <div class="media-body">
                <p>
                  271 Route 46 West, Suite C-202, <br>
                  Fairfield NJ 07004 <br>
                </p>  
              </div>
            </div>
            <div class="media">
              <img src="../../../assets/images/icons/call.svg" alt="call">
            <div class="media-body">
              <p><a href="tel:614-123-456-7890">(614) 123-456-7890</a></p>  
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/ic_email.svg" alt="mail">      
          <div class="media-body">
            <p><a href="mailto:info@honorvettech.com">info@honorvettech.com</a></p>
          </div>
        </div>    
      </div>  
      <div class="col-lg-5 col-md-6 footer-links">
            <h4>Main links</h4>
            <ul class="list-2 list-line">
              <li><a href="javascript:void(0);" routerLink="/about">About</a></li>
              <!-- <li><a href="https://honorvets.com/privacy-policy.html" target="_blank">Privacy policy</a></li> -->
              <li><a href="javascript:void(0);" routerLink="/privacy">Privacy policy</a></li>
              <li><a href="javascript:void(0);"  routerLink="/employers">Employers</a></li>
              <li><a href="javascript:void(0);" routerLink="/job-seekers">Job seekers</a></li>
              <!-- <li><a href="https://honorvets.com/terms.html" target="_blank">Terms and Conditions</a></li> -->
              <li><a href="javascript:void(0);" routerLink="/terms">Terms and Conditions</a></li>
              <li><a href="javascript:void(0);" routerLink="/faq">FAQ's</a></li>        
          </ul>
      </div>   
          <div class="col-lg-2 col-md-6 footer-info">
            <h4>Find us on</h4>
            <div class="social-links mt-3">
              <a href="https://www.facebook.com/honorvettech/" class="facebook" target="_blank"><img src="../../../assets/images/icons/fb.svg"></a>
              <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FHonorVetTech" class="twitter" target="_blank"><img src="../../../assets/images/icons/twitter.svg"></a>
              <a href="https://www.linkedin.com/company/honorvet-technologies/?_l=en_US" class="linkedin" target="_blank"><img src="../../../assets/images/icons/linkedin.svg"></a>
              <a href="https://www.pinterest.com/honorvettechnologies/" class="pin" target="_blank"><img src="../../../assets/images/icons/pin.svg"></a>
              <a href="https://www.instagram.com/honorvettech/" class="pin" target="_blank"><img src="../../../assets/images/instagram.svg"></a>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <div class="copyright">
      © 2024. <strong><span> All Rights Reserved HonorVet Technologies</span></strong>
    </div>  
</footer>
