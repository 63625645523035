import { Component, NgZone, Renderer2, OnInit, Inject, TemplateRef} from "@angular/core";
import { FormGroup,  FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ToastrService } from "ngx-toastr";
import * as CONSTANT from "../../services/constants";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"; 
import { DOCUMENT } from '@angular/common';
 
@Component({
  selector: "app-forgot-emp",
  templateUrl: "./forgot-emp.component.html",
  styleUrls: ["./forgot-emp.component.css"]
})
export class ForgotEmpComponent implements OnInit {
  disabled = ""
  active:any;
  showError:boolean=false;
  public loading = false;
  form: FormGroup;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private modalService: BsModalService,private formBuilder: FormBuilder,public route: ActivatedRoute, private router: Router,private api: AdminService,private toastrService: ToastrService) {
      this.form = this.formBuilder.group({
        email: ["", Validators.compose([Validators.required, Validators.pattern(CONSTANT.EMAIL_REGEX)])],
      });
     }
  
  
  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'hideMenuToggle');
    let data = localStorage.getItem("adminToken"); 
  } 
  forgotPassword() {
    this.showError = true; 
    if(this.form.valid) { 
      this.showError = false;
      this.loading = true; 
      this.api.postData(apiUrl._forgotEmp, this.form.value)
      .subscribe(async (res) => {
        this.toastrService.success(
          "Your password successfully sent on your email",
          ""
        );
        this.form.reset(); 
      },
      err => {
        this.toastrService.error("Error", err.error.message) 
        this.loading = false;
      });
    } else{
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
    
  } 
  }