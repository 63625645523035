import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ImageUrls } from "src/app/services/s3buket";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { MessagingService } from "src/app/services/messaging.service";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"]
})
export class HomePageComponent implements OnInit {
  recruiterImagePath = ImageUrls.BaseUrl + ImageUrls.directories.recruiter
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 400,
    nav: true,
    navText: ["prev", "next"],
    center: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  }; 
  userId;
  userData: any;
  limit = 4;
	skip = 1;
  constructor(private angularFireMessaging: AngularFireMessaging, private fcm: MessagingService,  private afAuth: AngularFireAuth,private router: Router,
    private api: AdminService) { }
 
  ngOnInit(): void {
    let deviceToken = this.getDeviceToken();
    localStorage.setItem("deviceToken",deviceToken) 
    this.userId =localStorage.getItem("userId")
    let user =  localStorage.getItem("login_type"); 
    let accessToken =localStorage.getItem("accessToken") 
    if (accessToken && this.userId != null && user == "userProfileCompleted"){
      this.router.navigate(["dashboard-job"]);
        
    } else if(accessToken && this.userId != null && user == "userLoggedIn"){ 
          this.router.navigate(["dashboard"]);
    } 
    this.getHomData()
  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("deviceToken");
    if ((deviceToken) && (deviceToken != "")) {
      return deviceToken;
    }
    else {
      this.fcm.requestPermission();
      deviceToken = localStorage.getItem("fireBaseToken")
      if (deviceToken)
        return deviceToken.toString()
      else
        return "NOTOKEN";
    }
  }
  getHomData(){
    this.api.getDatas(apiUrl._landingHome)
    .subscribe(async (res) => {
      this.userData = res.data;
      
    })
  }
  jobSalary(job: any): string {
    let salary = "";
    if (job?.price)
      salary = job?.price + "/";
    else if (job?.maximum && job?.minimum)
      salary = job?.minimum + "-$" + job?.maximum + "/";
    else
      salary = job?.minimum + job?.maximum;
    if (job?.rate)
      return "$" + salary + "" + job?.rate.split(" ")[1];
    else
      return "$" + salary;
  }

  dateInMilisecond(date){
    const d1 = new Date(date)
    const miliSecond = d1.getTime();
    return miliSecond
  }
}
