import {  NgModule,  CUSTOM_ELEMENTS_SCHEMA,  NO_ERRORS_SCHEMA,} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
/////service===
import { InterceptorService } from "./services/interceptor.service";
import { AsyncPipe } from "@angular/common";
import { DataSharingService } from "./services/data.share.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { DatePipe } from "@angular/common";

//npm package===

import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NgbModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
// import { SlickCarouselModule } from "ngx-slick-carousel";
import { BsDatepickerModule, BsDatepickerConfig } from "ngx-bootstrap/datepicker";

// firebase

import { environment } from "../environments/firebase";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging"; 
import { AngularFireDatabaseModule, AngularFireList, AngularFireDatabase } from "@angular/fire/compat/database";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
/////Component
import { LoaderComponent } from "./common/loader/loader.component";
import { LoginComponent } from "./extrnal/login/login.component";
import { ForgotComponent } from "./extrnal/forgot/forgot.component";
import { InternalComponent } from "./internal/internal.component";
import { HeaderComponent } from "./common/header/header.component";
// import { SidebarComponent } from "./common/sidebar/sidebar.component";
import { HomePageComponent } from "./extrnal/home-page/home-page.component";
import { ExternalHeaderComponent } from "./common/external-header/external-header.component";
import { FooterComponent } from "./common/footer/footer.component";
import { ChooseYourselfComponent } from "./extrnal/choose-yourself/choose-yourself.component"; 
import { RegisterComponent } from "./extrnal/register/register.component"; 
import { VerifyOtpComponent } from "./extrnal/verify-otp/verify-otp.component";
import { SetPasswordComponent } from "./extrnal/set-password/set-password.component"; 
import { NgOtpInputModule } from "ng-otp-input";
 
import { PhonePipe } from "./pipe/phone.pipe"
import { LoginEmpComponent } from "./employer/login-emp/login-emp.component";
import { ForgotEmpComponent } from "./employer/forgot-emp/forgot-emp.component";
import { CreateAccountComponent } from "./employer/create-account/create-account.component";
import { RecruiterInternalComponent } from "./recruiter-internal/recruiter-internal.component";
import { OtpComponent } from "./employer/otp/otp.component";
import { PasswordComponent } from "./employer/password/password.component";
import { LimitToPipe } from "./pipe/limit-to.pipe";
import { DateagoPipe } from "./pipe/dateago.pipe";
import { PhoneMaskDirective } from "./directive/phone-mask.directive";
import { NumberOnlyDirective } from "./directive/number-only.directive";
import { CapitalizeFirstDirective } from "./directive/capitalize-first.directive";
import { HeaderEmpComponent } from "./common/header-emp/header-emp.component"; 
import { EmployersComponent } from "./extrnal/employers/employers.component";
import { JobSeekersComponent } from "./extrnal/job-seekers/job-seekers.component";
import { CarouselModule } from "ngx-owl-carousel-o";

import { NgxStripeModule } from "ngx-stripe";
import { EmpSidebarComponent } from "./common/emp-sidebar/emp-sidebar.component";
import { Header2Component } from "./common/header2/header2.component";
import { RecruiterComponentsComponent } from "./recruiter-components/recruiter-components.component";

import { NgxPaginationModule } from "ngx-pagination";
import { JobseekerComponentComponent } from "./jobseeker-component/jobseeker-component.component";
import { HeaderJobseekerComponent } from "./common/header-jobseeker/header-jobseeker.component";
import { AboutComponent } from "./extrnal/about/about.component";
import { LightboxModule } from "ngx-lightbox";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { MessagingService } from "./services/messaging.service";
import { APP_BASE_HREF, PlatformLocation } from "@angular/common";

import { DateAgoConvertPipe } from "../app/pipe/date-ago-convert.pipe";

import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
// HighchartsChartModule 

import { HighchartsChartModule } from "highcharts-angular";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TooltipModule} from "ngx-bootstrap/tooltip";
import { FaqComponent } from "./extrnal/faq/faq.component";
import { TermsComponent } from "./extrnal/terms/terms.component";
import { PrivacyComponent } from "./extrnal/privacy/privacy.component";
import { CookiesComponent } from "./extrnal/cookies/cookies.component"; 
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { MobileTermsComponent } from "./extrnal/mobile-terms/mobile-terms.component";
import { MobilePolicyComponent } from "./extrnal/mobile-policy/mobile-policy.component";
import { MobileAboutComponent } from "./extrnal/mobile-about/mobile-about.component";
@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    DateagoPipe,
    LimitToPipe, 
    PhoneMaskDirective,
    NumberOnlyDirective,
    // CapitalizeFirstDirective,
    DateAgoConvertPipe,
    ExternalHeaderComponent,
    HeaderComponent,
    // SidebarComponent,
    FooterComponent,
    HomePageComponent,
    EmployersComponent,
    JobSeekersComponent,
    LoginComponent,
    InternalComponent,
    JobseekerComponentComponent,
    ForgotComponent,
    ChooseYourselfComponent,
    RegisterComponent,
    VerifyOtpComponent,
    SetPasswordComponent, 
    PhonePipe,
    HeaderEmpComponent,
    Header2Component,
    HeaderJobseekerComponent,
    EmpSidebarComponent,
    LoginEmpComponent,
    RecruiterComponentsComponent,
    RecruiterInternalComponent,
    ForgotEmpComponent,
    CreateAccountComponent,
    OtpComponent,
    PasswordComponent,
    AboutComponent,
    FaqComponent,
    TermsComponent,
    PrivacyComponent,
    CookiesComponent,
    // CommonImageUploadComponent, 
    MobileTermsComponent,
    MobilePolicyComponent,
    MobileAboutComponent
  ],
  imports: [
    NgxStripeModule.forRoot("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"),
    // NgxStripeModule.forRoot(environment.stripe.publicKey),
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbModule,
    NgbNavModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ServiceWorkerModule, 
    // SlickCarouselModule, 
    NgOtpInputModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
    CarouselModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    LightboxModule,
    AccordionModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    // HighchartsChartModule
    // NgxSliderModule
    
  ],
  exports:[
    PhoneMaskDirective,
    NgxStripeModule
  ],
  providers: [MessagingService,
    AsyncPipe,DatePipe,DateagoPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    },
    DataSharingService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}