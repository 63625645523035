<app-external-header></app-external-header>
<section class="pd-tb scrolled-offset">
  <div class="container">

   <div class="maindiv">
     

     <div class="forgot">
      <a href="javascript:void(0)" routerLink="/employer" class="bkbtn"><img src="../../../assets/images/arrow_back.svg" class="ht"> Back</a>
      <h2 class="heading2 mb-2">Forgot password</h2>
      <p class="para3 mb-5">Please enter your email address to receive a link</p>
      <form [formGroup]="form" (ngSubmit)="forgotPassword()">
        <div class="form-group pb-5">
          <label> Email </label>
          <input type="email" class="form-control" name="" placeholder="Enter"  formControlName="email">
          <div class="text-danger" *ngIf="form.controls['email'].hasError('required') && showError">
            *Please only use your  email.
          </div>
         <div class="text-danger" *ngIf="form.controls['email'].hasError('pattern') && showError">
            *Invalid email. Please try again.
        </div>
        </div>

        <div class="form-group pt-5">
          <input type="submit" class="commonBtn" value="Send" name="">
        </div>
      </form>
     </div>
   </div>


  </div>
 </section>