import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import swal from "sweetalert2";
import { Router } from "@angular/router";

// import { generalModel, icons, menu, BtnText, permission, tabArray, optionArray } from '../shared/models/general.model';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { apiUrl } from "../global/global";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  private allJobs: any = undefined;
  apiEndpoint: string = "";
  showLoader: boolean = true;
  access_token: any;
  public _albums = [];
  private search = new BehaviorSubject<String>('');
  private search$ = this.search.asObservable();
  constructor(private http: HttpClient, private router: Router) {
    this.apiEndpoint = environment.apiUrl;
    this.access_token = localStorage.getItem("accessToken");
  }

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  getData(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }
  getDataNew(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, {
      params: param, headers: new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      ),
    });
  }
  //
stopLoader(){
  this.showLoader=false;
  this.loader(false)
}
 
  getDatas(url) {
    return this.http.get<any>(this.apiEndpoint + url);
  }
  getDataExternalUrl(url) {
    return this.http.get<any>(url);
  }
  // getDatass(url) {
  //   return this.http.get<FilterOptions>(this.apiEndpoint + url);
  // }

  getDetailByidParams(data, url, param = {}) {
    return this.http.get<any>(this.apiEndpoint + url + data.id, {
      params: param,
    });
  }
  getDetail(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }

  getDetail1(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, param);
  }
  getDetailByid(data, url) {
    return this.http.get<any>(this.apiEndpoint + url + data.id, {});
  }
  putData(url, param) {
    return this.http.put<any>(this.apiEndpoint + url, param);
  } 
  putDataWihtoutParam(url) {
    return this.http.put<any>(this.apiEndpoint + url, '');
  }
  postUploadData(url, value) {
    this.showLoader=false;
    this.loader(false)
    return this.http.post<any>(this.apiEndpoint + url, value);
  }
  postData(url, value) {
    return this.http.post<any>(this.apiEndpoint + url, value);
  }
  postDataWihtoutParam(url) {
    return this.http.post<any>(this.apiEndpoint + url, '');
  }
  uploadFile(url, file) {
    return this.http.put<any>(url, file);
  }

  httpOptions1 = {
    headers: new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    ),
  };
  postDataNew(url, value) {
    return this.http.post<any>(
      this.apiEndpoint + url,
      value.toString(),
      this.httpOptions1
    );
  }
  putDataNew(url, value) {
    return this.http.put<any>(
      this.apiEndpoint + url,
      value.toString(),
      this.httpOptions1
    );
  }

  upateData(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }
  patchUpdate(url, value) {
    return this.http.patch<any>(this.apiEndpoint + url, value);
  }
  postUpdate(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }

  postNewData(url, key, value) {
    return this.http.post<any>(this.apiEndpoint + url, key, value);
  }
  updateData(url, value) {
    return this.http.patch<any>(this.apiEndpoint + url, value);
  }

  delete(url) {
    return this.http.delete<any>(this.apiEndpoint + url);
  }

  downloadPdf(url) {

    return this.http.get(url, { responseType: 'arraybuffer' });
  }

  deleteData(url, param) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: param,
    };
    return this.http.delete<any>(this.apiEndpoint + url, options);
  }

  showAlert(title: string, text: string) {
    swal.fire({
      title: '<figure><img src="../../assets/images/sucess.svg"></figure>',
      text: text,
      showConfirmButton: true,
      confirmButtonText: "Okay",
      confirmButtonColor: "#2FC863",
      timer: 1500,
    });
  }

  showError(title: string, text: string, confirmButtonText: string) {
    swal.fire({
      text: text,
      showConfirmButton: false,
      confirmButtonText: "Okay",
      confirmButtonColor: "#ff0000",
      timer: 1500,
    });
  }
  showErrorUpload(title: string, text: string) {
    swal.fire({
      text: text,
      showConfirmButton: false,
      confirmButtonText: "Okay",
      confirmButtonColor: "#ff0000",
      timer: 2500,
    });
  }

  errorAlertApi(text, status) {
    if(text != "This email address is  already registered with us."){
      swal
      .fire({
        title: window.navigator.onLine ? "Oops..." : "Please check your internet connection",
        text: text,  
        showConfirmButton: status ? true : false,
        allowOutsideClick: status ? false : true,
        allowEscapeKey: status ? true : false,
        confirmButtonText: "Got it",
        confirmButtonColor: "#FE831D",
        timer: status ? 1000000 : 2500,
      }).then((result) => {
        if (result.isConfirmed == true) {
          if (status) {
            localStorage.clear();       
            this.router.navigate(["/"]);
          }
        }
      });
    }
  }
  errorAlert(text, status) {
    swal
      .fire({
        title: status ?  "Oops...":'',
        text: text,
      })
      .then((result) => {
        if (status) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("firstName");
          localStorage.removeItem("lastName");
          localStorage.removeItem("image");
          localStorage.removeItem("s3Url");
          localStorage.removeItem("s3Folders");
          localStorage.removeItem("baseUrl");
          localStorage.removeItem("phoneNumber");
          localStorage.removeItem("deviceToken");
          this.router.navigate(["/home"]);
        }
      });
  }

  // Show and hide Loader
  private loaderSubject = new Subject<Boolean>();
  loaderState = this.loaderSubject.asObservable();

  loader(value) {
   this.loaderSubject.next(value);
  }

  // setdata(data) {
  //   for (let x of tabArray) {
  //     let array1 = {}
  //     for (let y of x.value) {
  //       array1[optionArray[y].name] = (data[x.name] == undefined || data[x.name][optionArray[y].name] == undefined ? false : data[x.name][optionArray[y].name]);
  //     }
  //     permission[x.name] = array1;
  //   }
  //   permission['status'] = "updated";
  //   return true;
  // }

  setSearch(search: String) {
    this.search.next(search)
  }
  getSearch(): Observable<String> {
    return this.search$;
  }

  showConfirmationDialog(message: string, positiveLabel: string, negativeLabel: string) {
    return swal.fire({
      html: "<p>" + message + "</p>",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: "#EF7E2B",
      cancelButtonColor: "#ffffff",
      confirmButtonText: positiveLabel,
      cancelButtonText: negativeLabel,
      reverseButtons: true,
      width: 500
    })
  }
  setJobsData(data: any) {
    this.allJobs = data;
  }
  getJobsData(): any {
    return this.allJobs;
  }
  getDataAPI() {
    var data = new Promise((resolve, reject) => {    
      let data = {
        skip:"0",
        limit:"50"
      }   
      this.getData(apiUrl._getJobsListing, data).subscribe(async (res) => {
        this.setJobsData(res.data.listing)
        resolve(res.data.listing);
      },
        err => {
          reject("err")
        });
    });
    return data;
  }
}
