<header id="header" class="d-flex align-items-center fixed-top ">
  <div class="container d-flex align-items-center justify-content-lg-between headerContainer">

    <div class="logo d-flex align-items-center">
      <a href="javascript:void(0);" routerLink="/dashboard-job">
        <!-- <img src="../../../assets/images/logo.svg" alt="HonorVet" class="img-fluid logo d-flex align-items-center  me-auto me-lg-0"> -->
        <img src="../../../assets/images/LOGO1.png" alt="HonorVet"
          class="img-fluid logo d-flex align-items-center  me-auto me-lg-0">
      </a>
      <div class="search-bar" id="parent" #parentDiv>
        <form class="search-form d-flex align-items-center">
          <input (ngModelChange)="onTextChange($event)" class="search" type="text" [(ngModel)]="search" name="query" placeholder="Find your dream job…" title="Enter search keyword" autocomplete="off" (click)="recentSearchList(search)" id="search" name="search" >
          <button [disabled]="!closeSearch" (click)="searchFun()" type="submit" title=""><img
              src="../../../assets/images/search.svg"></button>
          <div class="cross_icon" (click)="refreshPage()" *ngIf="closeSearch">
            <img src="../../../assets/images/ic_cancel_search.svg">
          </div>
          <!--  (window:mouseup)="autoCloseForDropdownCars($event)" -->
          <div class="dropdownParent" (click)="toggleDropdown()"> 
            <div class="dropdown-menu search-dropdown searchPannel" [ngClass]="allJobs?.length > 0 ? 'show':''" *ngIf="isDropdownOpen" > 
              <ul class="dropdown">
                <li *ngFor="let item of allJobs" (click)="drop()">
                  <a (click)="openSearchPage(item?._source);drop();" class="dropdown-item" data-toggle="modal"> {{item?._source.name || item?._source.searchKey}}
                  </a>
                </li> 
              </ul>
            </div>
            
            <div class="noDataFound dropdown-menu search-dropdown noData" *ngIf="noData">
              <h6>No Records Found</h6>
            </div> 
        </div>
        </form> 
       </div>
    </div>
    <nav id="navbar" class="navbar d-flex justify-content-between align-items-center order-last order-lg-0 ">

      <div class="menu" [class.menuClasse]="menuVariable" >
        <ul class="list-new" >
          <!--  routerLink="/chat"  -->
          <li><a href="javascript:void(0);" routerLinkActive="active" routerLink="/chat" (click)="drop()">Messages</a></li>
          <li><a routerLink="/all-jobs" routerLinkActive="active" (click)="drop()">Jobs</a></li>
          <li><a href="javascript:void(0);" routerLink="/profile" routerLinkActive="active" (click)="drop()">My profile</a></li>
        </ul>
      </div>
      <a class="menu_icon"  [class.menu_iconClass]="menu_icon_variable" (click)="openMenu()">   <span></span>
        <span></span>
        <span></span>
      </a>
    </nav>
 
    <div class="li-right login">
      <div class="navbar-collapse" id="navbarsExampleDefault">
        <ul class="d-flex align-items-center ">
          <li class="dropdown dropdown-notification" ngbDropdown (click)="onClickBell()" #myDrop="ngbDropdown">
            <a class="nav-link dropdown-toggle toolbar-icon " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" id="dropdown_notification" ngbDropdownToggle><img
                src="../../../assets/images/notification.svg" class="pe-3" alt="notification">
              <span class="dotGreen" *ngIf="message != null"></span>
              <span class="redDot" *ngIf="unreadCount != 0">{{unreadCount}}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-right dropdown-menu-media" ngbDropdownMenu
              aria-labelledby="dropdown_notification">
              <li class="dropdown-menu-header p-0">
                <div>
                  <span class="font-18 p-3">Notifications</span>
                </div>
              </li>
              <div class="pt-0">
                <div class="scroller" data-height="320px">
                  <div class="timeline-item " *ngFor="let data of allData">
                    <div class=" notificationmain" [ngClass]="data.isRead == 0 ? 'active' : ''"
                      (click)="redirectTo(data);myDrop.close();" style="cursor: pointer;">
                      <img src="assets/images/brands/ziptech.png" class="notify-img">
                      <div class="notify-data">
                        <p class="para"> <strong>{{data.title}}</strong> {{data.message}}
                          <small class="float-right ml-2 nowrap notitime">{{data.createdAt | dateago}}</small>
                        </p>
                        <a href="javascript:void(0);"
                          *ngIf="data.notificationType != '1' && data.notificationType != '11' && data.notificationType != '12'"
                          class="borderBtn applybtn mt-2" (click)="myDrop.close();" routerLink="/profile"> View Now </a>
                        <a href="javascript:void(0);"
                          *ngIf="data.notificationType == '11' && data.notificationType == '12'"
                          class="borderBtn applybtn mt-2" (click)="myDrop.close();" routerLink="/applied-jobs"> View Job
                        </a>
                      </div>
                    </div>
                  </div>
                  <a class="text-muted font-13 p-3 viewall" href="javascript:void(0);" (click)="myDrop.close();"
                    routerLink="/notification" style="display: inline-block;">view all</a>
                </div>
              </div>
            </ul>
          </li>
          <li class="dropdown dropdown-user nav-item show" ngbDropdown #myDrop1="ngbDropdown">
            <a class="nav-link nav-profile d-flex align-items-center pe-0  dropdown-toggle" href="javascript:void(0);"
              data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" id="dropdown_profile_area"
              ngbDropdownToggle><img
                [src]=" userDatas?.profileImage != null ? globalUrl + userDatas?.profileImage : '../../../assets/images/profile-img.jpg'"
                alt="Profile" class="rounded-circle">
              <span class="d-none d-md-block  ps-2">{{userDatas?.name}} </span><img
                src="../../../assets/images/ic_down_arrow.svg" class="arrow" alt="arrow"></a>

            <div
              class="dropdown-menu dropdown-arrow dropdown-menu-right admin-dropdown-menu dropdown-menu-animated profile-dropdown  dropdown-arrow "
              ngbDropdownMenu aria-labelledby="dropdown_profile_area">
              <ul>
                <li><a href="javascript:void(0);" (click)="myDrop1.close();" routerLink="/settings"> <img
                      src="../../../assets/images/ic_account_setting.svg" class="ht" alt="Comapany Profile">Account
                    settings</a></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a href="javascript:void(0);" (click)="myDrop1.close();" routerLink="/view-report"> <img
                      src="../../../assets/images/ic_report2.svg" class="ht" alt="account settings">View my
                    report</a></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a href="javascript:void(0);" (click)="logout();myDrop1.close();"> <img
                      src="../../../assets/images/icons/login.svg" class="ht" alt="Logout">Log out</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Mobile Menu -->
   
  </div>
</header>