<!-- START SIDEBAR-->
<nav class="page-sidebar" id="sidebar" (click)="closeMenuBar()">
  <div id="sidebar-collapse" class="scroller" data-height="100%" data-color="#ccc">
      <ul class="side-menu metismenu" *ngIf="userDatas?.Recruiter?.userType == 'SUPER_RECRUITER'">
          <li routerLinkActive="active">
              <a href="javascript:void(0);" routerLink="/dashboard" (click)="closeMenuBar()"> <img src="assets/images/dashboard.svg" class="sidebar-item-icon" alt="Dashboard">
                  <span class="nav-label"> Dashboard </span>
              </a>
          </li>

          <li routerLinkActive="active">
              <a href="javascript:void(0);" routerLink="/jobs" (click)="closeMenuBar()">  <img src="assets/images/ic_jobs.svg" class="sidebar-item-icon" alt="Jobs">
                  <span class="nav-label">Jobs</span>
              </a>
          </li> 

           <li routerLinkActive="active">
              <a href="javascript:void(0);" routerLink="/search" (click)="closeMenuBar()">  <img src="assets/images/ic_search.svg" class="sidebar-item-icon" alt="Search"> 
                  <span class="nav-label">Search</span>
              </a>
          </li> 
          <li routerLinkActive="active">
              <a href="javascript:void(0);" routerLink="/users" (click)="closeMenuBar()"><img src="assets/images/ic_users.svg" class="sidebar-item-icon" alt="Users"> 
                  <span class="nav-label">Users</span>
              </a>
          </li>
          
          <li routerLinkActive="active">
              <a style="position: relative;" href="javascript:void(0);" routerLink="/message"><img src="assets/images/ic_message.svg" class="sidebar-item-icon" alt="Message"> 
                  <span class="nav-label"> Messages  <small class="unreadMsgCount" *ngIf="countArray.length>0">{{countArray.length}}</small> </span>
              </a>
          </li>

          <li routerLinkActive="active">
              <a href="javascript:void(0);" routerLink="/groups"> <img src="assets/images/ic_group_permission.svg" class="sidebar-item-icon" alt="permissions"> 
                  <span class="nav-label"> Groups & Permissions</span>
              </a>
          </li>

          <li routerLinkActive="active" class="parentNav">
              <a href="javascript:void(0);" routerLink="/report/user-activity"> <img src="assets/images/ic_report.svg" class="sidebar-item-icon" alt="Report"> 
                  <span class="nav-label"> Reports</span>
              </a>
              <ul class="sub-menu">
                <li routerLinkActive="active"><a href="javascript:void(0)" routerLink="/report/user-activity">User activity</a></li>
                <li routerLinkActive="active"><a href="javascript:void(0)" routerLink="/report/serach-detail">Search detail</a></li>
                <li routerLinkActive="active"><a href="javascript:void(0)" routerLink="/report/inventory-usage">Inventory usage</a></li>
                <li routerLinkActive="active"><a href="javascript:void(0)" routerLink="/report/job-report">Job performance</a></li>
              </ul>
          </li>
          <li routerLinkActive="active">
              <a href="javascript:void(0);" routerLink="/candidates"> <img src="assets/images/ic_candidates.svg" class="sidebar-item-icon" alt="Report"> 
                  <span class="nav-label"> My candidates</span>
              </a>
          </li> 
      </ul>
      <ul class="side-menu metismenu" *ngIf="userDatas?.Recruiter?.userType != 'SUPER_RECRUITER'"> 
          <li routerLinkActive="active" *ngIf="userPermissionData?.dashboard == 1">
              <a href="javascript:void(0);" routerLink="/dashboard"> <img src="assets/images/dashboard.svg" class="sidebar-item-icon" alt="Dashboard">
                  <span class="nav-label"> Dashboard </span>
              </a>
          </li>

          <li routerLinkActive="active" *ngIf="userPermissionData?.createJob == 1 || userPermissionData?.editTeamMemberJob == 1 || userPermissionData?.viewTeamMemberJob == 1">
              <a href="javascript:void(0);" routerLink="/jobs">  <img src="assets/images/ic_jobs.svg" class="sidebar-item-icon" alt="Jobs">
                  <span class="nav-label">Jobs</span>
              </a>
          </li> 

           <li routerLinkActive="active" *ngIf="userPermissionData?.search == 1">
              <a href="javascript:void(0);" routerLink="/search">  <img src="assets/images/ic_search.svg" class="sidebar-item-icon" alt="Search"> 
                  <span class="nav-label">Search</span>
              </a>
          </li> 
          <li routerLinkActive="active" *ngIf="userPermissionData?.editTeamMember == 1 || userPermissionData?.addTeamMember == 1 || userPermissionData?.deleteTeamMember == 1">
              <a href="javascript:void(0);" routerLink="/users"><img src="assets/images/ic_users.svg" class="sidebar-item-icon" alt="Users"> 
                  <span class="nav-label">Users</span>
              </a>
          </li>
          
          <li routerLinkActive="active" *ngIf="userPermissionData?.messages == 1">
              <a href="javascript:void(0);" routerLink="/message"><img src="assets/images/ic_message.svg" class="sidebar-item-icon" alt="Message"> 
                  <span class="nav-label"> Messages</span>
              </a>
          </li>

          <li routerLinkActive="active" *ngIf="userPermissionData?.groupAndPermission == 1">
              <a href="javascript:void(0);" routerLink="/groups"> <img src="assets/images/ic_group_permission.svg" class="sidebar-item-icon" alt="permissions"> 
                  <span class="nav-label"> Groups & Permissions</span>
              </a>
          </li>

          <li routerLinkActive="active" *ngIf="userPermissionData?.reports == 1">
              <a href="javascript:void(0);"> <img src="assets/images/ic_report.svg" class="sidebar-item-icon" alt="Report"> 
                  <span class="nav-label"> Reports</span>
              </a>
          </li>
          <li routerLinkActive="active" *ngIf="userPermissionData?.myCandidate == 1">
              <a href="javascript:void(0);" routerLink="/candidates"> <img src="assets/images/ic_candidates.svg" class="sidebar-item-icon" alt="Report"> 
                  <span class="nav-label"> My candidates</span>
              </a>
          </li> 

          
      </ul>
  </div>
</nav>
<!-- END SIDEBAR-->