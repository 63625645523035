
<header id="header" class="header d-flex align-items-center fixed-top ">
  <div class="page-brand">
    <a class="link"  href="javascript:void(0);" routerLink="/dashboard">
        <span class="brand">
          <!-- <img src="../../../assets/images/logo.svg" alt="HonorVet" class="img-fluid logo d-flex align-items-center  me-auto me-lg-0"> -->
        <img src="../../../assets/images/LOGO1.png" alt="HonorVet" class="img-fluid logo d-flex align-items-center  me-auto me-lg-0">
        </span>
        <span class="brand-mini">
            <img src="../../../assets/images/fav.png">
        </span>
    </a>
</div>
<div class="flexbox flex-1">
    <!-- START TOP-LEFT TOOLBAR-->
    <ul class="nav navbar-toolbar">
        <li>
           <a class="nav-link sidebar-toggler js-sidebar-toggler" href="javascript:;" (click)="toggleNavbar($event)">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </a>
        </li>
    </ul>
    <!-- END TOP-LEFT TOOLBAR-->
    <!-- START TOP-RIGHT TOOLBAR-->
    <ul class="nav navbar-toolbar">
        
        <li class="dropdown dropdown-notification" ngbDropdown #myDrop="ngbDropdown" (click)="onClickBell()" >
            <a class="nav-link dropdown-toggle toolbar-icon" data-toggle="dropdown" id="dropdown_notification" ngbDropdownToggle><img src="../../../assets/images/notification.svg" alt="notification">
              <span class="dotGreen" *ngIf="message != null"></span>
              <span class="redDot" *ngIf="unreadCount != 0">{{unreadCount}}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-right dropdown-menu-media" ngbDropdownMenu
              aria-labelledby="dropdown_notification">
              <li class="dropdown-menu-header p-0">
                <div>
                  <span class="font-18 p-3">Notifications</span>
                </div>
              </li>
              <div class="pt-0">
                <div class="scroller" data-height="320px">
                  <div class="timeline-item " *ngFor="let data of allData">
                    <div class=" notificationmain" [ngClass]="data.isRead == 0 ? 'active' : ''"
                      (click)="redirectTo(data);myDrop.close();" style="cursor: pointer;">
                      <!-- <img src="assets/images/brands/ziptech.png" class="notify-img"> -->
                      <div class="notify-data">
                        <p class="para"> <strong>{{data.title}}</strong> {{data.message}}
                          <small class="float-right ml-2 nowrap notitime">{{data.createdAt | dateago}}</small>
                        </p>
                        <a href="javascript:void(0);" class="borderBtn applybtn mt-2" (click)="redirectTo(data);myDrop.close();" routerLink="/notification"> View Now </a> 
                      </div>
                    </div>
                  </div>
                  <a class="text-muted font-13 p-3 viewall" href="javascript:void(0);" (click)="myDrop.close();"
                    routerLink="/notification" style="display: inline-block;">view all</a>
                </div>
              </div>
            </ul>
        </li>
        <li class="dropdown dropdown-user nav-item dropdown" ngbDropdown #myDrop="ngbDropdown">
          <a class="nav-link nav-profile d-flex align-items-center pe-0  dropdown-toggle" href="javascript:void(0);" data-toggle="dropdown" role="button" aria-haspopup="true"
          aria-expanded="false" id="dropdown_profile_area" ngbDropdownToggle><img
          [src]=" userDatas?.Recruiter?.profileImage != null ? globalUrl + userDatas?.Recruiter?.profileImage : '../../../assets/images/profile-img.jpg'" alt="Profile" class="rounded-circle">
            <span class="d-none d-md-block  ps-2">{{userDatas?.Recruiter?.name}} </span><img src="../../../assets/images/ic_down_arrow.svg" class="arrow" alt="arrow"></a>

            <div class="dropdown-menu dropdown-arrow dropdown-menu-right admin-dropdown-menu dropdown-menu-animated profile-dropdown  dropdown-arrow "
         ngbDropdownMenu aria-labelledby="dropdown_profile_area">
                <ul>
                    <li><a href="javascript:void(0);" ngbDropdownItem routerLink="/profile"> <img src ="../../../assets/images/ic_company_profile.svg"  class="ht" alt="Comapany Profile">Company profile</a></li>
                    <li>
                      <hr class="dropdown-divider">
                    </li>
                    <li><a href="javascript:void(0);" ngbDropdownItem routerLink="/account-settings"> <img src ="../../../assets/images/ic_account_settings.svg"  class="ht" alt="account settings">Account settings</a></li>
                    <li>
                        <hr class="dropdown-divider">
                      </li>
                      <li><a href="javascript:void(0);" ngbDropdownItem routerLink="/help"> <img src ="../../../assets/images/ic_help.svg" class="ht" alt="Help">Help</a></li>
                      <li>
                        <hr class="dropdown-divider">
                      </li>
                      <li><a href="javascript:void(0);" ngbDropdownItem (click)="logout()"> <img src ="../../../assets/images/logout.svg" class="ht" alt="Logout">Logout</a></li>
                    
                </ul>

        
            </div>
        </li>
    </ul>
    <!-- END TOP-RIGHT TOOLBAR-->
</div>
</header>