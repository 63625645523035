import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup,  FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "src/app/extrnal/shared.service";
import { apiUrl } from "src/app/global/global";
import { AdminService } from "src/app/services/admin.service";
import { Subject } from "rxjs";

@Component({
  selector: "app-otp",
  templateUrl: "./otp.component.html",
  styleUrls: ["./otp.component.css"]
})
export class OtpComponent implements OnInit {
  otp: string | undefined;
  showOtpComponent = true;
  userData;
  showError:boolean=false;
  resendOtp:boolean=false;
  modalRef?: BsModalRef;
  public form: FormGroup;
  @ViewChild("ngOtpInput", { static: false}) ngOtpInput: any;
  config = { 
    placeholder: "-",
    length: 4 
  };
  display:any;
  statSec:number | undefined;
  accessToken: any; 
  timerObject; 
  queryName:any;
  queryEmail:any;
  userInfoObserver = new Subject<string>();
  constructor(private modalService: BsModalService,private formBuilder: FormBuilder,public route: ActivatedRoute, private router: Router,private api: AdminService,private toastrService: ToastrService,private sharedService: SharedService ) {
    this.form = this.formBuilder.group({
      otp: ["", Validators.compose([Validators.required])], 
    }); 
  }
  ngOnInit(): void {  
    window.localStorage.setItem("isPasswordSet","0") 
    // this.accessToken= localStorage.getItem("accessToken"); 
    this.accessToken = this.sharedService.accessToken;  
    this.userData= JSON.parse(window.localStorage.getItem("userDetail")!); 
    this.route.queryParams.subscribe(params => {
      this.queryName = params['name'];
      this.queryEmail = params['email'];
  });
    this.timer(2);
  }

  // back
  // back(){
  //   this.router.navigate(["/create-account"]);
  // }
  back(){
    this.router.navigate(['create-account'], { queryParams: {name: this.queryName, email: this.queryEmail} }) 
  }
  onOtpChange(otp) {
    this.otp = otp;
  }
  setVal(val) {
    this.ngOtpInput.setValue(val); 
  }
  timer(minute) {
    // let minute = 2;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    this.timerObject = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) { 
        clearInterval(this.timerObject);
        this.resendOtp = true;
      }
    }, 1000);
  }
  verify(){    
    if(this.otp == null){
      this.showError = true;
      setTimeout(() => {
        this.showError = false
      }, 2000);
      return
    }
    const data ={
      "otp":this.otp,
    }
    this.api.putData(apiUrl._otp, data)
      .subscribe(async (res) => {
        this.toastrService.success("OTP verified  successfully!"); 
        this.router.navigate(["/password"]);
      }, err => {
        this.toastrService.error("Error", err.error.message)
      })
  }
  resendOTP(){ 
    this.resendOtp = false
    clearInterval(this.timerObject);
    this.timer(2);
    this.api.putDataWihtoutParam(apiUrl._resendSignUpOtp)
      .subscribe(async (res) => {
        this.otp = ""
        this.ngOtpInput.setValue("");
        this.toastrService.success("OTP Resend successfully!");  
      }, err => {
          this.toastrService.error("Error", err.error.message)
      })
  }
  // check if only number
  preventNumbers(event): boolean { 
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }
  
}
