<app-external-header></app-external-header>

<section class="loginPage marginTop">
  <div class="progress">
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="loginText">
          <h2 class="loginTitle"> Login </h2>
          <p> Welcome back! </p>
        </div>

        <div class="loginForm">
          <form [formGroup]="form">
            <div class="form-group">
              <label> Email </label>
              <input type="email" class="form-control" name="" placeholder="Enter Email" formControlName="email"
                id="email" />
              <div class="text-danger" *ngIf="form.controls['email'].hasError('required') && showError">
                *Please enter email.
              </div>
              <div class="text-danger" *ngIf="form.controls['email'].hasError('pattern') && showError">
                *Please enter valid email.
              </div>
            </div>
            <div class="form-group mb-1">
              <label> Password </label>
              <input type="{{showPwd?'text':'password'}}" class="form-control" name="" placeholder="Enter Password"
                id="pwd" formControlName="password" />
              <a href="javascript:void(0);" [class]="showPwd? 'hide':'show'" (click)="showPwd= !showPwd">
                <img class="hide"
                  [src]="showPwd? '../../../assets/images/password.svg':'../../../assets/images/ic_eye_open.svg'"
                  alt="password">
                <i></i>
              </a>
              <div class="text-danger" *ngIf="form.controls['password'].hasError('required') && showError">
                *Please enter password.
              </div>
            </div>
            <div class="form-group remember row ">
              <div class="forgot-password col-lg-12 col-md-12 text-right">
                <a href="javascript:void(0)" routerLink="/forgot-emp" class="forgot-btn">Forgot Password?</a>
              </div>
            </div>
            <div class="form-group">
              <input type="submit" class="commonBtn" value="Login" name="" [disabled]="!(form.valid)" (click)="login()">
            </div>
            <div class="form-group">
              <p class="text-center"> Don't have an account? <a href="javascript:void(0)" routerLink="/create-account"
                  class="register orange"> Register </a> </p>
              <br>
              <!-- <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="customCheckBox1" formControlName="isAccepted" [checked]="form.value.isAccepted ==1" (change)="form.value.isAccepted ==1">
                  <label class="custom-control-label" for="customCheckBox1">I have read all the <a href="https://honorvets.com/terms.html"  class="register orange" target="_blank"> Terms & Condition </a> and <a href="https://honorvets.com/privacy-policy.html"  class="register orange" target="_blank"> Privacy Policy </a></label>
                </div> 
                <div class="text-danger" *ngIf="form.controls['isAccepted'].hasError('required') && showError">
                  *Please accept the policy.
                </div> -->
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-8 col-md-6 col-sm-12">
        <div class="loginImg"></div>

      </div>
    </div>
  </div>
</section>