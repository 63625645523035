import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.css"]
})
export class TermsComponent implements OnInit {
  accessToken;
  constructor() { }

  ngOnInit(): void {
    this.accessToken = localStorage.getItem("accessToken");
  }

}
