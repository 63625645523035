<app-external-header></app-external-header>
<!-- progress -->
<div class="progress">
  <div class="progress-bar" role="progressbar" aria-label="Basic example" style="width:5%" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"></div>
</div>

<!-- End -->

<!-- Main Page Start -->
<section class="loginPage">
  <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-sm-12"> 
            <div class="loginText">
              <h2 class="loginTitle"> Create account </h2> 
              <p> it’s very simple to register with us </p>      
            </div>
  
            <div class="loginForm">
              <form [formGroup]="form">
                <div class="form-group upperLetter">
                    <label> Enter your full name </label>
                    <input type="text" class="form-control" name="" placeholder="Enter name" formControlName="name" id="name" maxlength="30">
                    <div class="text-danger" *ngIf="form.controls['name'].hasError('required') && showError">
                      *Please enter name.
                    </div> 
                    <div class="text-danger" *ngIf="form.controls['name'].hasError('minlength') && showError">
                        *Minimum length of 3 characters.
                    </div>
                    <div class="text-danger" *ngIf="form.controls['name'].hasError('pattern') && showError">
                        *No spaces allowed.
                    </div>
                </div>
                <div class="form-group">
                    <label> Email </label>
                    <input type="email" class="form-control" name="" placeholder="Enter email" formControlName="email" id="email"/>
                    <div class="text-danger" *ngIf="form.controls['email'].hasError('required') && showError">
                      *Please enter email.
                    </div>
                    <div class="text-danger" *ngIf="form.controls['email'].hasError('pattern') && showError">
                      *Please enter valid email.
                    </div> 
                </div>
  
                <div class="form-group">
                  <input type="submit" class="commonBtn" value="Register" name="" (click)="signUp()">
                    <!-- <a href="javascript:void(0);" routerLink="/verify-otp" class="commonBtn mt-3" >Register</a> -->
                </div>
  
                <div class="form-group">
                    <p class="text-center"> or </p>
                </div>
                
                <a href="javascript:;" class="socialMediaLink form-group"  (click)="loginFacebook()">
                  <img  class="icon" src="assets/images/facebook.svg" alt="facebook">
                  <span class="text">Login with Facebook </span>
                </a>
  
                <a href="javascript:;" class="socialMediaLink form-group" >
                  <!-- <div class="gLog" #gbutton></div>  -->
                  <div id="google-button"></div> 
                  <img class="icon" src="../../../assets/images/google.svg" alt="google">
                  <span class="text">Login with Google </span>
                </a>
                <a href="javascript:;" class="socialMediaLink form-group" (click)="signInWithApple()"  *ngIf="osType">
                  <img  class="icon" src="assets/images/apple.svg" alt="apple">
                  <span class="text">Login with Apple </span>
                </a>
                <div class="form-group haveAccount">
                    <p class="center"> Already have an account?  <a href="javascript:void(0);" routerLink="/login" class="register orange" > Login here </a> </p>
                    
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="customCheckBox1" formControlName="isAccepted" [checked]="form.value.isAccepted ==1" (change)="form.value.isAccepted ==1">
                      <label class="custom-control-label" for="customCheckBox1">I have read all the <a href="/terms" routerLink="/terms" target="_blank"  class="register orange"> Terms and Conditions </a> and <a href="/privacy" routerLink="/privacy"  target="_blank" class="register orange"> Privacy Policy </a></label>
                    </div> 
                    <div class="text-danger" *ngIf="form.value.isAccepted !=1 && showError">
                      *Please accept the policy.
                    </div>
                </div>
  
              </form>
            </div>
         
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12">
            <div class="loginImg"></div>
        </div>
      </div>
  </div>
</section>

<!-- End -->