import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const numbersOnly = value.replace(/\D/g, '');
    const firstPart = numbersOnly.slice(0, 3);
    const secondPart = numbersOnly.slice(3, 6);
    const thirdPart = numbersOnly.slice(6, 10);

    let formattedValue = '';

    if (firstPart) {
      formattedValue = `${firstPart}`;
    }

    if (secondPart) {
      formattedValue = `${formattedValue}-${secondPart}`;
    }

    if (thirdPart) {
      formattedValue = `${formattedValue}-${thirdPart}`;
    }

    return formattedValue;
  }
}