import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "dateAgoConvert"
})
export class DateAgoConvertPipe implements PipeTransform {
  currentDate = new Date();
  constructor(private datePipe: DatePipe) { }
  transform(value: any, args?: any): any {
    var seconds = Math.floor(((Date.now()) - value) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    var result;
    if (days > 365) {
      
      result = Math.floor(days / 365) + " Years";
    
    } else if (days > 30) {
      result = Math.floor(days / 30) + " Months";
    } else if (days > 7) {
      result = Math.floor(days / 7) + " Weeks";
    } else if (days > 1) {
      result = Math.floor(days / 1) + " Days";
    } else if (hours > 1) {
      result = hours + " Hours";
    } else if (minutes >= 1) {
      result = minutes + " Minute" + (minutes > 1 ? "s" : "");
    } else {
      result = "Now";
    }

    return result == "Now" ? result : result + " ago";
  }
}
