import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./extrnal/login/login.component";
import { ForgotComponent } from "./extrnal/forgot/forgot.component";
import { HomePageComponent } from "./extrnal/home-page/home-page.component";
import { AuthGuard } from "./services/auth.guard";
import {NotFoundComponent } from "./extrnal/not-found/not-found.component";
import { ChooseYourselfComponent } from "./extrnal/choose-yourself/choose-yourself.component"; 
import { RegisterComponent } from "./extrnal/register/register.component"; 
import { VerifyOtpComponent } from "./extrnal/verify-otp/verify-otp.component";
import { SetPasswordComponent } from "./extrnal/set-password/set-password.component"; 
import { LoginEmpComponent } from "./employer/login-emp/login-emp.component";
import { ForgotEmpComponent } from "./employer/forgot-emp/forgot-emp.component";
import { CreateAccountComponent } from "./employer/create-account/create-account.component";
import { OtpComponent } from "./employer/otp/otp.component";
import { PasswordComponent } from "./employer/password/password.component";
import { EmployersComponent } from "./extrnal/employers/employers.component";
import { JobSeekersComponent } from "./extrnal/job-seekers/job-seekers.component";
import { AboutComponent } from "./extrnal/about/about.component";
import { FaqComponent } from "./extrnal/faq/faq.component";
import { TermsComponent } from "./extrnal/terms/terms.component";
import { PrivacyComponent } from "./extrnal/privacy/privacy.component";
import { CookiesComponent } from "./extrnal/cookies/cookies.component";
import { MobileTermsComponent } from "./extrnal/mobile-terms/mobile-terms.component";
import { MobilePolicyComponent } from "./extrnal/mobile-policy/mobile-policy.component";
import { MobileAboutComponent } from "./extrnal/mobile-about/mobile-about.component";
const routes: Routes = [
  { path: "", redirectTo: "home",pathMatch: "full"},
  { path: "home", component: HomePageComponent },
  { path: "employers", component: EmployersComponent },
  { path: "job-seekers", component: JobSeekersComponent },
  { path: "about", component: AboutComponent },
  { path: "faq", component: FaqComponent },
  { path: "terms", component: TermsComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "cookies", component: CookiesComponent },
  { path: "login", component: LoginComponent },
  { path: "forgot", component: ForgotComponent },
  { path: "choose-yourself", component: ChooseYourselfComponent },
  { path: "register", component: RegisterComponent },
  { path: "verify-otp", component: VerifyOtpComponent },
  { path: "set-password", component: SetPasswordComponent }, 
  // employer
  { path: "employer", component: LoginEmpComponent },
  { path: "forgot-emp", component: ForgotEmpComponent },
  { path: "create-account", component: CreateAccountComponent },
  { path: "otp", component: OtpComponent },
  { path: "password", component: PasswordComponent },

  // app side pages
  { path: "mobile-terms", component: MobileTermsComponent },
  { path: "mobile-policy", component: MobilePolicyComponent },
  { path: "mobile-about", component: MobileAboutComponent },
  {
    matcher: url => {
      const user_type = localStorage.getItem("login_type");
      const accessToken = localStorage.getItem("accessToken");
      if (user_type === "userProfileInCompleted" && accessToken != "") {
        return url.length ? { consumed: [] } : { consumed: url };
      }
      return null;
    },
    canActivate: [AuthGuard],loadChildren: () => import("src/app/internal/internal.module").then(m => m.InternalModule)
  },
  {
    matcher: url => {
      const user_type = localStorage.getItem("login_type");
      const accessToken = localStorage.getItem("accessToken");
      if (user_type === "user" && accessToken != "") {
        return url.length ? { consumed: [] } : { consumed: url };
      }
      return null;
    },
    canActivate: [AuthGuard],loadChildren: () => import("src/app/internal/internal.module").then(m => m.InternalModule)
  },
  {
    matcher: url => {
      const user_type = localStorage.getItem("login_type");
      const accessToken = localStorage.getItem("accessToken");
      if (user_type === "userProfileCompleted" && accessToken != "") {
        return url.length ? { consumed: [] } : { consumed: url };
      }
      return null;
    },
    canActivate: [AuthGuard],loadChildren: () =>  import("src/app/jobseeker-component/jobseeker-component.module").then(m => m.JobseekerComponentModule)
  },
  {
    matcher: url => {
      const user_type = localStorage.getItem("login_type");
      const accessToken = localStorage.getItem("accessToken");
      if (user_type === "employer") {
        return url.length ? { consumed: [] } : { consumed: url };
      } 
      return null;
      
    },
    canActivate: [AuthGuard],loadChildren: () => import("src/app/recruiter-internal/recruiter-internal.module").then(m => m.RecruiterInternalModule)
  },
  {
    matcher: url => {
      const user_type = localStorage.getItem("login_type");
      const accessToken = localStorage.getItem("accessToken");
      if (user_type === "userNotApproved") {
        return url.length ? { consumed: [] } : { consumed: url };
      } 
      return null;
      
    },
    canActivate: [AuthGuard],loadChildren: () => import("src/app/recruiter-internal/recruiter-internal.module").then(m => m.RecruiterInternalModule)
  },
  {
    matcher: url => {
      const user_type = localStorage.getItem("login_type");
      const accessToken = localStorage.getItem("accessToken");
      if (user_type === "userLoggedIn" && accessToken != "" ) {
        return url.length ? { consumed: [] } : { consumed: url };
      }
      return null;
    },
    canActivate: [AuthGuard],loadChildren: () =>  import("src/app/recruiter-components/recruiter-components.module").then(m => m.RecruiterComponentsModule)
  },
  // { path: "",canActivate: [AuthGuard],loadChildren: () => import("src/app/internal/internal.module").then(m => m.InternalModule)},
  // { path: "**",redirectTo: "home",pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
