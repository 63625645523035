 <app-external-header></app-external-header>
 <!-- Main Page Start --> 
 <section class="loginPage marginTop ">
  <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-sm-12">
          <div class="loginText">
            <h2 class="loginTitle"> Login </h2> 
            <p> Welcome back!</p>      
          </div>

          <div class="form">
            <form [formGroup]="form">
              <div class="form-group">
                  <label> Email </label>
                  <input type="email" class="form-control" name="" placeholder="Enter Email" formControlName="email" id="email"/>
                  <div class="text-danger" *ngIf="form.controls['email'].hasError('required') && showError">
                    *Please enter email.
                  </div>
                  <div class="text-danger" *ngIf="form.controls['email'].hasError('pattern') && showError">
                    *Please enter valid email.
                  </div> 
              </div>
              <div class="form-group mb-1">
                  <label> Password </label>
                  <input  type="{{showPwd?'text':'password'}}" class="form-control" name="" placeholder="Enter Password"  id="pwd" formControlName="password"/>
                  <a href="javascript:void(0);" [class]="showPwd? 'hide':'show'" (click)="showPwd= !showPwd"> 
                    <img class="hide" [src]="showPwd? '../../../assets/images/password.svg':'../../../assets/images/ic_eye_open.svg'" alt="password"> 
                    <i ></i>
                  </a>
                  <div class="text-danger" *ngIf="form.controls['password'].hasError('required') && showError">
                    *Please enter password.
                  </div>
              </div>
              <div class="form-group remember"> 
                <div class="forgot-password text-right">
                  <a href="javascript:void(0);" class="forgot-btn" routerLink="/forgot">Forgot Password?</a>
                </div>    
              </div>
              <div class="form-group">
                  <input type="submit" class="commonBtn " value="Login" name="" (click)="login()" [disabled]="!(form.valid)">
              </div>

              <div class="form-group">
                  <p class="text-center"> or </p>
              </div>
             
              <a href="javascript:void(0);" class="socialMediaLink form-group" (click)="loginFacebook()">
                <img  class="icon" src="../../../assets/images/facebook.svg" alt="facebook">
                <span class="text">Login with Facebook </span>
              </a> 
              
              <!-- <div class="gLog" #gbutton></div>  -->

              <a href="javascript:;" class="socialMediaLink form-group" >
                <div id="google-button"></div> 
                <img class="icon" src="../../../assets/images/google.svg" alt="google">
                <span class="text">Login with Google </span>
              </a>
              <a href="javascript:;" class="socialMediaLink form-group" (click)="signInWithApple()" *ngIf="osType">
                <img  class="icon" src="../../../assets/images/apple.svg" alt="apple">
                <span class="text">Login with Apple </span>
              </a>
              <div class="form-group">
                  <p class="text-center"> Don't have an account? <a href="javascript:;" class="register orange" data-toggle="modal" data-target="#registerModal" data-dismiss="modal" (click)="open(template)"> Register </a> </p>
                  <!-- <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheckBox1" formControlName="isAccepted" [checked]="form.value.isAccepted ==1" (change)="form.value.isAccepted ==1">
                    <label class="custom-control-label" for="customCheckBox1">I have read all the <a href="https://honorvets.com/terms.html"  class="register orange" target="_blank"> Terms & Condition </a> and <a href="https://honorvets.com/privacy-policy.html"  class="register orange" target="_blank"> Privacy Policy </a></label>
                  </div> 
                  <div class="text-danger" *ngIf="form.controls['isAccepted'].hasError('required') && showError">
                    *Please accept the policy.
                  </div> -->
              </div>

            </form>
          </div>
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12">
            <div class="loginImg"></div>
        </div>
      </div>
  </div>
</section>

<!-- End -->
<ng-template #template>
  <div class="modal-body  text-center  ">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="this.modalRef?.hide()"><span aria-hidden="true">&times;</span></button>
    <h5 class="modal-title"> How will you best describe yourself? </h5>
    <p> Let us know you better to serve you better. </p>
    <form [formGroup]="registerForm">
      <ul class="selectUser">
        <li>
            <input type="radio" name="userType" id="radio1" class="selectUser_radio" checked="" formControlName="userType" value="0"/>
            <label for="radio1" class="selectUser_label"><span class="img"><img src="assets/images/ic_veteran.svg" /></span> <span class="ml-0"> I am a veteran </span> </label>
        </li>
        <li>
            <input type="radio" name="userType" id="radio2" class="selectUser_radio" value="1" formControlName="userType"/>
            <label for="radio2" class="selectUser_label" ><span class="img"><img src="assets/images/ic_veteran_family.png" /></span> <span> I am a veteran’s family member </span> </label>
        </li>
      </ul>
      <div class="text-danger" *ngIf="registerForm.controls['userType'].hasError('required') && showError">
        *Please select on option.
      </div>
      <input type="submit" class="commonBtn" value="Continue" name="" (click)="signUp()">

      <!-- <a href="javascript:void(0)" routerLink="/choose-yourself" class="commonBtn" (click)="this.modalRef?.hide()"> Continue </a> -->
    </form>
  </div>
</ng-template>