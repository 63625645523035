  import { environment } from "../../environments/environment";

  export var DATE_REGEX = 'dd/MM/yyyy, hh:mm a';
  export var NAME_REGEX = '^[a-zA-Z ]*$';

  export var MOBILE_REGEX = '^[0-9.]*$';

  // export var NO_SPACE_REGEX = '[0-9a-zA-Z][0-9a-zA-Z ]+';

  export var EMAIL_REGEX =/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
// export var EMAIL_REGEX = /^([a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+)+$/;
  // export var SPACE_REGEX =  /^[a-zA-Z][a-zA-Z0-9. ]+$/;
  // export const SPACE_REGEXName = /[a-zA-Z][a-zA-Z ]*/
  export const SPACE_REGEXName = /[\S]/g
  export var SPACE_REGEX =  /((?!.*[\s])(?=.*[A-Z])(?=.*\d))/;
  export var Percentage_REGEX =  /^([1-9]|[1-9][0-9]|100)$/;
  export let URL_REGEX = /^(http?|ftp|https:\/\/)?([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  export let URL_REGEXForPersonality = /^(http?|ftp|https):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

  export var PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&])/;
  // export var PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

  export let linkedin_Regex = /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/

  export var LIMIT = 10;
  export var LIMITBE = 5;
  export var LIMIT4 = 4;
  export var LIMITS = 21;
  export var MAX_SIZE = 3;


  export var MAX_IMAGE_SIZE = 5000000;
  export const Month_Dates = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
  export const Week_Days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  export const Year_Months=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'sep', 'Oct', 'Nov', 'Dec'];
  export const Week_Number=[0,1,2,3,4,5,6];
  export const Year_Number=[0,1,2,3,4,5,6,7,8,9,10,11]

  export const Exclude_Candidate = [{ id: 0, name: 'Viewed'}, { id: 2, name: 'Emailed'}, { id: 7, name: 'Added To The List'}, { id: 5, name: 'Forwarded'}]; 
  // export const Languages = [{ id: "english", name: 'english'},{ id: "german", name: 'german'},{ id: "french", name: 'french'},{ id: "spanish", name: 'spanish'},]; 
  export const Languages = [
    {"code":"ab","name":"abkhaz","nativeName":"аҧсуа"},
    {"code":"aa","name":"afar","nativeName":"Afaraf"},
    {"code":"af","name":"afrikaans","nativeName":"Afrikaans"},
    {"code":"ak","name":"akan","nativeName":"Akan"},
    {"code":"sq","name":"albanian","nativeName":"Shqip"},
    {"code":"am","name":"amharic","nativeName":"አማርኛ"},
    {"code":"ar","name":"arabic","nativeName":"العربية"},
    {"code":"an","name":"aragonese","nativeName":"Aragonés"},
    {"code":"hy","name":"armenian","nativeName":"Հայերեն"},
    {"code":"as","name":"assamese","nativeName":"অসমীয়া"},
    {"code":"av","name":"avaric","nativeName":"авар мацӀ, магӀарул мацӀ"},
    {"code":"ae","name":"avestan","nativeName":"avesta"},
    {"code":"ay","name":"aymara","nativeName":"aymar aru"},
    {"code":"az","name":"azerbaijani","nativeName":"azərbaycan dili"},
    {"code":"bm","name":"bambara","nativeName":"bamanankan"},
    {"code":"ba","name":"bashkir","nativeName":"башҡорт теле"},
    {"code":"eu","name":"basque","nativeName":"euskara, euskera"},
    {"code":"be","name":"belarusian","nativeName":"Беларуская"},
    {"code":"bn","name":"bengali","nativeName":"বাংলা"},   
    {"code":"bi","name":"bislama","nativeName":"Bislama"},
    {"code":"bs","name":"bosnian","nativeName":"bosanski jezik"},
    {"code":"br","name":"breton","nativeName":"brezhoneg"},
    {"code":"bg","name":"bulgarian","nativeName":"български език"},
    {"code":"my","name":"burmese","nativeName":"ဗမာစာ"},
    {"code":"ca","name":"catalan","nativeName":"Català"},
    {"code":"ca","name":"Valencian","nativeName":"Català"},
    {"code":"ch","name":"chamorro","nativeName":"Chamoru"},
    {"code":"ce","name":"chechen","nativeName":"нохчийн мотт"},
    {"code":"ny","name":"chichewa","nativeName":"chiCheŵa, chinyanja"},
    {"code":"ny","name":"Chewa","nativeName":"chiCheŵa, chinyanja"},
    {"code":"ny","name":"Nyanja","nativeName":"chiCheŵa, chinyanja"},
    {"code":"zh","name":"chinese","nativeName":"中文 (Zhōngwén), 汉语, 漢語"},
    {"code":"cv","name":"chuvash","nativeName":"чӑваш чӗлхи"},
    {"code":"kw","name":"cornish","nativeName":"Kernewek"},
    {"code":"co","name":"corsican","nativeName":"corsu, lingua corsa"},
    {"code":"cr","name":"cree","nativeName":"ᓀᐦᐃᔭᐍᐏᐣ"},
    {"code":"hr","name":"croatian","nativeName":"hrvatski"},
    {"code":"cs","name":"czech","nativeName":"česky, čeština"},
    {"code":"da","name":"danish","nativeName":"dansk"},
    {"code":"dv","name":"divehi","nativeName":"ދިވެހި"},
    {"code":"dv","name":"Dhivehi","nativeName":"ދިވެހި"},
    {"code":"dv","name":"Maldivian","nativeName":"ދިވެހި"},
    {"code":"nl","name":"dutch","nativeName":"Nederlands, Vlaams"},
    {"code":"en","name":"english","nativeName":"English"},
    {"code":"eo","name":"esperanto","nativeName":"Esperanto"},
    {"code":"et","name":"estonian","nativeName":"eesti, eesti keel"},
    {"code":"ee","name":"ewe","nativeName":"Eʋegbe"},
    {"code":"fo","name":"faroese","nativeName":"føroyskt"},
    {"code":"fj","name":"fijian","nativeName":"vosa Vakaviti"},
    {"code":"fi","name":"finnish","nativeName":"suomi, suomen kieli"},
    {"code":"fr","name":"french","nativeName":"français, langue française"},
    {"code":"ff","name":"fula","nativeName":"Fulfulde, Pulaar, Pular"},
    {"code":"ff","name":"Pular","nativeName":"Fulfulde, Pulaar, Pular"},
    {"code":"ff","name":"Fulah","nativeName":"Fulfulde, Pulaar, Pular"},
    {"code":"ff","name":"Pulaar","nativeName":"Fulfulde, Pulaar, Pular"},
    {"code":"gl","name":"galician","nativeName":"Galego"},
    {"code":"ka","name":"georgian","nativeName":"ქართული"},
    {"code":"de","name":"german","nativeName":"Deutsch"},
    {"code":"el","name":"greek, Modern","nativeName":"Ελληνικά"},
    {"code":"gn","name":"guaraní","nativeName":"Avañeẽ"},
    {"code":"gu","name":"gujarati","nativeName":"ગુજરાતી"},
    {"code":"ht","name":"haitian","nativeName":"Kreyòl ayisyen"},
    {"code":"ht","name":"Haitian Creole","nativeName":"Kreyòl ayisyen"},
    {"code":"ha","name":"hausa","nativeName":"Hausa, هَوُسَ"},
    {"code":"he","name":"hebrew","nativeName":"עברית"},
    {"code":"hz","name":"herero","nativeName":"Otjiherero"},
    {"code":"hi","name":"hindi","nativeName":"हिन्दी, हिंदी"},
    {"code":"ho","name":"hiri motu","nativeName":"Hiri Motu"},
    {"code":"hu","name":"hungarian","nativeName":"Magyar"},
    {"code":"ia","name":"interlingua","nativeName":"Interlingua"},
    {"code":"id","name":"indonesian","nativeName":"Bahasa Indonesia"},
    {"code":"ie","name":"interlingue","nativeName":"Originally called Occidental; then Interlingue after WWII"},
    {"code":"ga","name":"irish","nativeName":"Gaeilge"},
    {"code":"ig","name":"igbo","nativeName":"Asụsụ Igbo"},
    {"code":"ik","name":"inupiaq","nativeName":"Iñupiaq, Iñupiatun"},
    {"code":"io","name":"ido","nativeName":"Ido"},
    {"code":"is","name":"icelandic","nativeName":"Íslenska"},
    {"code":"it","name":"italian","nativeName":"Italiano"},
    {"code":"iu","name":"inuktitut","nativeName":"ᐃᓄᒃᑎᑐᑦ"},
    {"code":"ja","name":"japanese","nativeName":"日本語 (にほんご／にっぽんご)"},
    {"code":"jv","name":"javanese","nativeName":"basa Jawa"},
    {"code":"kl","name":"kalaallisut","nativeName":"kalaallisut, kalaallit oqaasii"},
    {"code":"kl","name":"greenlandic","nativeName":"kalaallisut, kalaallit oqaasii"},
    {"code":"kn","name":"kannada","nativeName":"ಕನ್ನಡ"},
    {"code":"kr","name":"kanuri","nativeName":"Kanuri"},
    {"code":"ks","name":"kashmiri","nativeName":"कश्मीरी, كشميري‎"},
    {"code":"kk","name":"kazakh","nativeName":"Қазақ тілі"},
    {"code":"km","name":"khmer","nativeName":"ភាសាខ្មែរ"},
    {"code":"ki","name":"kikuyu","nativeName":"Gĩkũyũ"},
    {"code":"ki","name":"gikuyu","nativeName":"Gĩkũyũ"},
    {"code":"rw","name":"kinyarwanda","nativeName":"Ikinyarwanda"},
    {"code":"ky","name":"kirghiz","nativeName":"кыргыз тили"},
    {"code":"ky","name":"kyrgyz","nativeName":"кыргыз тили"},
    {"code":"kv","name":"komi","nativeName":"коми кыв"},
    {"code":"kg","name":"kongo","nativeName":"KiKongo"},
    {"code":"ko","name":"korean","nativeName":"한국어 (韓國語), 조선말 (朝鮮語)"},
    {"code":"ku","name":"kurdish","nativeName":"Kurdî, كوردی‎"},
    {"code":"kj","name":"kwanyama","nativeName":"Kuanyama"},
    {"code":"kj","name":"kuanyama","nativeName":"Kuanyama"},
    {"code":"la","name":"latin","nativeName":"latine, lingua latina"},
    {"code":"lb","name":"luxembourgish","nativeName":"Lëtzebuergesch"},
    {"code":"lb","name":"letzeburgesch","nativeName":"Lëtzebuergesch"},
    {"code":"lg","name":"luganda","nativeName":"Luganda"},
    {"code":"li","name":"limburgish","nativeName":"Limburgs"},
    {"code":"li","name":"limburgan","nativeName":"Limburgs"},
    {"code":"li","name":"limburger","nativeName":"Limburgs"},
    {"code":"ln","name":"lingala","nativeName":"Lingála"},
    {"code":"lo","name":"lao","nativeName":"ພາສາລາວ"},
    {"code":"lt","name":"lithuanian","nativeName":"lietuvių kalba"},
    {"code":"lu","name":"luba-Katanga","nativeName":""},
    {"code":"lv","name":"latvian","nativeName":"latviešu valoda"},
    {"code":"gv","name":"manx","nativeName":"Gaelg, Gailck"},
    {"code":"mk","name":"macedonian","nativeName":"македонски јазик"},
    {"code":"mg","name":"malagasy","nativeName":"Malagasy fiteny"},
    {"code":"ms","name":"malay","nativeName":"bahasa Melayu, بهاس ملايو‎"},
    {"code":"ml","name":"malayalam","nativeName":"മലയാളം"},
    {"code":"mt","name":"maltese","nativeName":"Malti"},
    {"code":"mi","name":"māori","nativeName":"te reo Māori"},
    {"code":"mr","name":"marathi","nativeName":"मराठी"},
    {"code":"mh","name":"marshallese","nativeName":"Kajin M̧ajeļ"},
    {"code":"mn","name":"mongolian","nativeName":"монгол"},
    {"code":"na","name":"nauru","nativeName":"Ekakairũ Naoero"},
    {"code":"nv","name":"navajo, navaho","nativeName":"Diné bizaad, Dinékʼehǰí"},
    {"code":"nb","name":"norwegian bokmål","nativeName":"Norsk bokmål"},
    {"code":"nd","name":"north ndebele","nativeName":"isiNdebele"},
    {"code":"ne","name":"nepali","nativeName":"नेपाली"},
    {"code":"ng","name":"ndonga","nativeName":"Owambo"},
    {"code":"nn","name":"norwegian nynorsk","nativeName":"Norsk nynorsk"},
    {"code":"no","name":"norwegian","nativeName":"Norsk"},
    {"code":"ii","name":"nuosu","nativeName":"ꆈꌠ꒿ Nuosuhxop"},
    {"code":"nr","name":"south ndebele","nativeName":"isiNdebele"},
    {"code":"oc","name":"occitan","nativeName":"Occitan"},
    {"code":"oj","name":"ojibwe, ojibwa","nativeName":"ᐊᓂᔑᓈᐯᒧᐎᓐ"},
    {"code":"cu","name":"old church slavonic","nativeName":"ѩзыкъ словѣньскъ"},
    {"code":"cu","name":"church slavic","nativeName":"ѩзыкъ словѣньскъ"},
    {"code":"cu","name":"church slavonic","nativeName":"ѩзыкъ словѣньскъ"},
    {"code":"cu","name":"old bulgarian","nativeName":"ѩзыкъ словѣньскъ"},
    {"code":"cu","name":"old slavonic","nativeName":"ѩзыкъ словѣньскъ"},
    {"code":"om","name":"oromo","nativeName":"Afaan Oromoo"},
    {"code":"or","name":"oriya","nativeName":"ଓଡ଼ିଆ"},
    {"code":"os","name":"ossetian","nativeName":"ирон æвзаг"},
    {"code":"os","name":"ossetic","nativeName":"ирон æвзаг"},
    {"code":"pa","name":"punjabi","nativeName":"ਪੰਜਾਬੀ, پنجابی‎"},
    {"code":"pi","name":"pāli","nativeName":"पाऴि"},
    {"code":"fa","name":"persian","nativeName":"فارسی"},
    {"code":"pl","name":"polish","nativeName":"polski"},
    {"code":"ps","name":"pashto","nativeName":"پښتو"},
    {"code":"ps","name":"pushto","nativeName":"پښتو"},
    {"code":"pt","name":"portuguese","nativeName":"Português"},
    {"code":"qu","name":"quechua","nativeName":"Runa Simi, Kichwa"},
    {"code":"rm","name":"romansh","nativeName":"rumantsch grischun"},
    {"code":"rn","name":"kirundi","nativeName":"kiRundi"},
    {"code":"ro","name":"romanian, moldavian, moldovan","nativeName":"română"},
    {"code":"ru","name":"russian","nativeName":"русский язык"},
    {"code":"sa","name":"sanskrit","nativeName":"संस्कृतम्"},
    {"code":"sc","name":"sardinian","nativeName":"sardu"},
    {"code":"sd","name":"sindhi","nativeName":"सिन्धी, سنڌي، سندھی‎"},
    {"code":"se","name":"northern sami","nativeName":"Davvisámegiella"},
    {"code":"sm","name":"samoan","nativeName":"gagana faa Samoa"},
    {"code":"sg","name":"sango","nativeName":"yângâ tî sängö"},
    {"code":"sr","name":"serbian","nativeName":"српски језик"},
    {"code":"gd","name":"scottish gaelic","nativeName":"Gàidhlig"},
    {"code":"gd","name":"gaelic","nativeName":"Gàidhlig"},
    {"code":"sn","name":"shona","nativeName":"chiShona"},
    {"code":"si","name":"sinhala","nativeName":"සිංහල"},
    {"code":"si","name":"sinhalese","nativeName":"සිංහල"},
    {"code":"sk","name":"slovak","nativeName":"slovenčina"},
    {"code":"sl","name":"slovene","nativeName":"slovenščina"},
    {"code":"so","name":"somali","nativeName":"Soomaaliga, af Soomaali"},
    {"code":"st","name":"southern sotho","nativeName":"Sesotho"},
    {"code":"es","name":"spanish","nativeName":"español, castellano"},
    {"code":"es","name":"castilian","nativeName":"español, castellano"},
    {"code":"su","name":"sundanese","nativeName":"Basa Sunda"},
    {"code":"sw","name":"swahili","nativeName":"Kiswahili"},
    {"code":"ss","name":"swati","nativeName":"SiSwati"},
    {"code":"sv","name":"swedish","nativeName":"svenska"},
    {"code":"ta","name":"tamil","nativeName":"தமிழ்"},
    {"code":"te","name":"telugu","nativeName":"తెలుగు"},
    {"code":"tg","name":"tajik","nativeName":"тоҷикӣ, toğikī, تاجیکی‎"},
    {"code":"th","name":"thai","nativeName":"ไทย"},
    {"code":"ti","name":"tigrinya","nativeName":"ትግርኛ"},
    {"code":"bo","name":"tibetan standard","nativeName":"བོད་ཡིག"},
    {"code":"bo","name":"tibetan","nativeName":"བོད་ཡིག"},
    {"code":"tk","name":"turkmen","nativeName":"Türkmen, Түркмен"},
    {"code":"tl","name":"tagalog","nativeName":"Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"},
    {"code":"tn","name":"tswana","nativeName":"Setswana"},
    {"code":"to","name":"tonga","nativeName":"faka Tonga"},
    {"code":"tr","name":"turkish","nativeName":"Türkçe"},
    {"code":"ts","name":"tsonga","nativeName":"Xitsonga"},
    {"code":"tt","name":"tatar","nativeName":"татарча, tatarça, تاتارچا‎"},
    {"code":"tw","name":"twi","nativeName":"Twi"},
    {"code":"ty","name":"tahitian","nativeName":"Reo Tahiti"},
    {"code":"ug","name":"uyghur","nativeName":"Uyƣurqə, ئۇيغۇرچە‎"},
    {"code":"ug","name":"uighur","nativeName":"Uyƣurqə, ئۇيغۇرچە‎"},
    {"code":"uk","name":"ukrainian","nativeName":"українська"},
    {"code":"ur","name":"urdu","nativeName":"اردو"},
    {"code":"uz","name":"uzbek","nativeName":"zbek, Ўзбек, أۇزبېك‎"},
    {"code":"ve","name":"venda","nativeName":"Tshivenḓa"},
    {"code":"vi","name":"vietnamese","nativeName":"Tiếng Việt"},
    {"code":"vo","name":"volapük","nativeName":"Volapük"},
    {"code":"wa","name":"walloon","nativeName":"Walon"},
    {"code":"cy","name":"welsh","nativeName":"Cymraeg"},
    {"code":"wo","name":"wolof","nativeName":"Wollof"},
    {"code":"fy","name":"western frisian","nativeName":"Frysk"},
    {"code":"xh","name":"xhosa","nativeName":"isiXhosa"},
    {"code":"yi","name":"yiddish","nativeName":"ייִדיש"},
    {"code":"yo","name":"yoruba","nativeName":"Yorùbá"},
    {"code":"za","name":"zhuang","nativeName":"Saɯ cueŋƅ, Saw cuengh"},
    {"code":"za","name":"chuang","nativeName":"Saɯ cueŋƅ, Saw cuengh"}
  ]; 
  export const USER_STATUS_FILTER = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: 'Active Users', selected: false }, { key: 2, value: 'Deactivated Users', selected: false }];

  export const GENDER_FILTER = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: 'Male', selected: false },
    { key: 2, value: 'Female', selected: false }];

  export const AGE_FILTER_FROM = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: '10', selected: false },
    { key: 2, value: '20', selected: false }];

  export const LIMIT_RECORD_LIST = [{ key: 1, value: 10, selected: true },
    { key: 2, value: 20, selected: false }, { key: 3, value: 50, selected: false }, { key: 4, value: 100, selected: false },
    { key: 5, value: 200, selected: false }];

  export const PLAN_FILTER = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: 'Free', selected: false },
    { key: 2, value: 'Premium', selected: false }];

  export const AGE_FILTER_TO = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: '20', selected: false },
    { key: 2, value: '30', selected: false }];


