import { Component, OnInit } from "@angular/core";
import { UserDetailsService } from "../services/user/user-details.service";

@Component({
  selector: "app-jobseeker-component",
  templateUrl: "./jobseeker-component.component.html",
  styleUrls: ["./jobseeker-component.component.css"]
})
export class JobseekerComponentComponent implements OnInit {

  constructor(private userService: UserDetailsService) {

  }

  ngOnInit(): void {
  }

}
