<header id="header" class="d-flex align-items-center fixed-top ">
  <div class="container d-flex align-items-center justify-content-lg-between headerContainer">

    <div class="logo d-flex align-items-center">
      <a href="javascript:void(0);" routerLink="/home">
        <!-- <img src="../../../assets/images/logo.svg" alt="HonorVet" class="img-fluid logo d-flex align-items-center  me-auto me-lg-0"> -->
        <img src="../../../assets/images/LOGO1.png" alt="HonorVet" class="img-fluid logo d-flex align-items-center  me-auto me-lg-0">
      </a>
       
    </div> 
      <div class="li-right login">
        <div class="navbar-collapse" id="navbarsExampleDefault">
          <ul class="navbar-nav ml-auto">
              <li class="nav-item dropdown" ngbDropdown #myDrop="ngbDropdown">
                  <a class="nav-link nav-profile d-flex align-items-center pe-0  dropdown-toggle" href="javascript:void(0);" data-toggle="dropdown" role="button" aria-haspopup="true"
                  aria-expanded="false" id="dropdown_profile_area" ngbDropdownToggle><img [src]=" userDatas?.Recruiter?.profileImage != null ?  globalUrl + userDatas?.Recruiter?.profileImage : '../../../assets/images/profile-img.jpg'" alt="Profile" class="rounded-circle">
                    <span class="d-none d-md-block  ps-2">{{userDatas?.Recruiter?.name}} </span><img src="../../../assets/images/ic_down_arrow.svg" class="arrow" alt="arrow"></a>
                    <div class="dropdown-menu ml-3 dropdown-menu-right dropdown-menu-animated profile-dropdown  dropdown-arrow "
                    ngbDropdownMenu aria-labelledby="dropdown_profile_area">
                      <ul>
                          <li><a href="javascript:void(0);" (click)="myDrop.close();" class="dropdown-item"  (click)="logout()"> <img src="../../../assets/images/icons/login.svg" class="ht" alt="login">Logout</a></li>
                      </ul>
                  </div> 
              </li>
          </ul>
      </div>
      </div>
      <!-- Mobile Menu -->
  </div>
</header>
 